// import DateFnsUtils from "@date-io/date-fns";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import { format } from "date-fns";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "css/controls/customdatepicker.css";
import { FormControl } from "@mui/material";
import MDTypography from "components/MDTypography";
import { position } from "stylis";

export default function CustomDatePicker({
  name,
  label,
  value,
  onChange,
  disabled,
  ...props
}) {
  const convertToDefEventPara = (name, value) => ({
    target: {
      name,
      value,
    },
  });

  return (
    <FormControl variant="outlined" disabled={disabled} fullWidth sx={{position:"relative"}}>
      {<MDTypography sx={{position:"absolute",top:"-1rem",left:"0.5rem",fontSize:"0.7rem !important"}} className={"font-unset"}>{label}</MDTypography>}
      <DatePicker
        disabled={disabled}
        showIcon
        toggleCalendarOnIconClick
        inputVariant="outlined"
        label={label}
        dateFormat={"dd/MM/yyyy"}
        name={name}
        placeholderText={label}
        selected={Date.parse(value)}
        onChange={(date) => onChange(convertToDefEventPara(name, date))}
        {...props}
        className={"custom-datepicker " + props.className}
      />
    </FormControl>
  );
}
