import { useEffect, useState } from "react";

// react-router-dom components
import { Link, useLocation, useNavigate } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";

// @mui icons

//  React components
import { Icon, TextField } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";

// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";

// Images
import { setGlobalState } from "Global";
import { basicAPI, client } from "api";
import { Form } from "components/controls/useForm";
import bgImage from "assets/images/background.png";
import JSEncrypt from "jsencrypt";

function Basic() {
  const [state, setState] = useState({});
  const location = useLocation();
  const navigation = useNavigate();
  const [key, setKey] = useState();

  const handleChange = (event) => {
    setState({ ...state, [event.target.id]: event.target.value });
  };

  const handleSignin = (e) => {
    e.preventDefault();
    basicAPI
      .signinNew(state.username, encryptPassword(state.password))
      .then((res) => {
        if (res.status == 200) {
          sessionStorage.setItem("token", `Bearer ${res.data.access_token}`);
          client.defaults.headers.common.Authorization = `${sessionStorage.getItem(
            "token"
          )}`;
          sessionStorage.setItem("user", JSON.stringify(res.data));
          if (sessionStorage.getItem("history-url") && sessionStorage.getItem("history-url")!="/authentication/sign-in") {
            let tempUrl = sessionStorage.getItem("history-url");
            sessionStorage.removeItem("history-url");
            navigation(tempUrl);
          } else if (
            location.state?.navigateTo &&
            location.state?.navigateTo.pathname != "/authentication/sign-in"
          ) {
            sessionStorage.removeItem("history-url");
            navigation(location.state.navigateTo);
          } else {
            sessionStorage.removeItem("history-url");
            navigation("/");
          }
        }
      })
      .catch((error) => {
        // console.log(error)
        if ([401, 422].includes(error.response?.status)) {
          setGlobalState("error", {
            open: true,
            message: "Invalid Username or password. Please try again.",
            type: "error",
            code: error.response.status,
          });
        }
      });
  };

  const encryptPassword = (password) => {
    const jsEncrypt = new JSEncrypt();
    jsEncrypt.setPublicKey(key);
    return jsEncrypt.encrypt(password);
  };

  const getKey = () => {
    basicAPI
      .signinKey()
      .then((res) => {
        if (res.status == 200) {
          setKey(res.data);
        }
      })
      .catch((error) => {
        // console.log(error)
        if ([401, 422].includes(error.response?.status)) {
          setGlobalState("error", {
            open: true,
            message: "Invalid Username or password. Please try again.",
            type: "error",
            code: error.response.status,
          });
        }
      });
  };

  const handleLogin = () => {
    basicAPI
      .login()
      .then((res) => {
        let url = res.data.url;
        window.location.replace(url);
      })
      .catch((error) => {});
  };

  useEffect(() => {
    getKey();
  }, []);


  return (
    <BasicLayout image={bgImage}>
      <Form onSubmit={handleSignin}>
        <Card>
          <MDBox
            variant="gradient"
            bgColor="info"
            borderRadius="lg"
            coloredShadow="info"
            mx={2}
            mt={-2}
            p={2}
            mb={1}
            textAlign="center"
          >
            <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
              Sign in
            </MDTypography>
          </MDBox>
          <MDBox pt={2} pb={2} px={2}>
            <MDBox component="form" role="form">
              <MDBox mb={1} sx={{ display: "flex", justifyContent: "center" }}>
                <TextField
                  type="email"
                  label="Email"
                  name="username"
                  id="username"
                  onChange={handleChange}
                  variant="outlined"
                  fullWidth
                  sx={{
                    marginLeft: "0 !important",
                    marginRight: "0 !important",
                  }}
                  required={true}
                />
              </MDBox>
              <MDBox mb={1} sx={{ display: "flex", justifyContent: "center" }}>
                <TextField
                  type="password"
                  label="Password"
                  name="password"
                  id="password"
                  onChange={handleChange}
                  variant="outlined"
                  fullWidth
                  sx={{
                    marginLeft: "0 !important",
                    marginRight: "0 !important",
                  }}
                  required={true}
                />
              </MDBox>
              <MDBox sx={{ position: "relative" }}>
                <Link to={"/authentication/reset-password"}>
                  <MDBox
                    sx={{
                      float: "right",
                      marginBottom: "1rem",
                      marginTop: "-1rem",
                      marginRight: "2rem",
                      cursor: "pointer",
                      textDecoration: "underline",
                    }}
                  >
                    Forgot Password?
                  </MDBox>
                </Link>
              </MDBox>
              <MDBox mt={1} mb={1}>
                <MDButton
                  variant="gradient"
                  color="info"
                  type="submit"
                  onClick={handleSignin}
                  fullWidth
                >
                  sign in
                </MDButton>
              </MDBox>
              <MDBox mt={1} mb={1} textAlign="center">
                <MDTypography variant="button" color="text">
                  New User?{" "}
                  <MDTypography
                    component={Link}
                    to="/authentication/sign-up"
                    variant="button"
                    color="info"
                    fontWeight="medium"
                    textGradient
                  >
                    Sign Up
                  </MDTypography>
                </MDTypography>
              </MDBox>
              <MDBox mt={-1} mb={1} textAlign="center">
                <MDTypography variant="button" color="text">
                  Sign in with Microsoft?{" "}
                  <MDTypography
                    variant="button"
                    color="info"
                    fontWeight="medium"
                    textGradient
                    onClick={handleLogin}
                    sx={{ cursor: "pointer" }}
                  >
                    <Icon sx={{ paddingTop: "0.1rem" }} fontSize="lg">
                      window
                    </Icon>
                    Login
                  </MDTypography>
                </MDTypography>
              </MDBox>
            </MDBox>
          </MDBox>
        </Card>
      </Form>
    </BasicLayout>
  );
}

export default Basic;
