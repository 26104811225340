import { FormControl, Grid, Tooltip } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import { useEffect, useState } from "react";
// import "react-medium-image-zoom/dist/styles.css";
import { faFilter } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { setGlobalState } from "Global";
import { DashboardAPI } from "api";
import CustomAutoComplete from "components/controls/AutoComplete";
import Controls from "components/controls/Controls";
import { format } from "date-fns";
import AnalyticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard/analytics";
import { BaseChart } from "examples/Charts/baseChart";
import { useNavigate } from "react-router-dom";
import DialogWrapper from "utils/dialog/dialogWrapper";
import { useGlobalState } from "Global";
const date = new Date();

export const basicFilterMapping = {
  StartDate: format(new Date(date.getFullYear(), 0, 1), "yyyy-MM-dd"),
  EndDate: format(date, "yyyy-MM-dd"),
};

export const tileFilterMapping = {
  StartDate: format(
    new Date(date.getFullYear(), date.getMonth(), 1),
    "yyyy-MM-dd"
  ),
  EndDate: format(date, "yyyy-MM-dd"),
};

const dateFilters = ["StartDate", "EndDate"];

const dataMap = {
  OTCBASE: {},
  OTCDSADAKAR: {},
  OTDBASE: {},
  OTDPHC: {},
  VFR: {},
};

function MainDashboard() {
  const [basicFilterOptions, setBasicFilterOptions] = useState({});
  const [basicFilter, setBasicFilter] = useState(basicFilterMapping);
  const [tileFilter, setTileFilter] = useState(tileFilterMapping);
  const [overallData, setOverallData] = useState({
    OTCBASE: [{}],
    OTCDSADAKAR: [{}],
    OTDBASE: [{}],
    OTDPHC: [{}],
    VFR: [{}, {}, {}],
  });
  const [trendsData, setTrendsData] = useState(dataMap);
  const [trendGroupBy, setTrendGroupBy] = useState({
    name: "Weekly trend",
    value: "weekly",
  });
  const [trendGroupByOptions, setTrendGroupByOptions] = useState([]);
  const [showFilters, setShowFilters] = useState(false);
  const [filterType, setFilterType] = useState("Tile");
  const [isLoadingMap, setIsLoadingMap] = useState({
    OTCBASETile: true,
    OTCDSADAKARTile: true,
    OTDBASETile: true,
    OTDPHCTile: true,
    VFRTile: true,

    OTCBASETrend: true,
    OTCDSADAKARTrend: true,
    OTDBASETrend: true,
    OTDPHCTrend: true,
    VFRTrend: true,
  });
  const [filterNameMapping] = useGlobalState("mapping");

  const navigation = useNavigate();

  const getData = (type, newFilter = null) => {
    const dynamicFunction = DashboardAPI[`get${type}DataTrend`];
    setIsLoadingMap((prevLoadingMap) => {
      return { ...prevLoadingMap, [type + "Trend"]: true };
    });
    dynamicFunction(
      {
        ...convertToParams(newFilter ? newFilter : basicFilter),
        type: trendGroupBy.value,
      },
      convertToParams(newFilter ? newFilter : basicFilter)
    )
      .then(async (res) => {
        if (res.status == 200) {
          setIsLoadingMap((prevLoadingMap) => {
            return { ...prevLoadingMap, [type + "Trend"]: false };
          });
          // setOverallData((prevOverallData) => {
          //   return { ...prevOverallData, [type]: res.data.tile_data };
          // });
          setTrendsData((prevTrendsData) => {
            return { ...prevTrendsData, [type]: res.data.graph };
          });
        }
      })
      .catch((error) => {
        setIsLoadingMap((prevLoadingMap) => {
          return { ...prevLoadingMap, [type + "Trend"]: false };
        });
      });
  };

  const getOverallData = (type, newFilter = null) => {
    const dynamicFunction = DashboardAPI[`get${type}DataTile`];
    setIsLoadingMap((prevLoadingMap) => {
      return { ...prevLoadingMap, [type + "Tile"]: true };
    });
    dynamicFunction(
      {
        ...convertToParams(newFilter ? newFilter : tileFilter),
        type: trendGroupBy.value,
      },
      convertToParams(newFilter ? newFilter : tileFilter)
    )
      .then(async (res) => {
        if (res.status == 200) {
          setIsLoadingMap((prevLoadingMap) => {
            return { ...prevLoadingMap, [type + "Tile"]: false };
          });
          setOverallData((prevOverallData) => {
            return { ...prevOverallData, [type]: res.data.tile_data };
          });
          // setTrendsData((prevTrendsData) => {
          //   return { ...prevTrendsData, [type]: res.data.graph };
          // });
        }
      })
      .catch((error) => {
        setIsLoadingMap((prevLoadingMap) => {
          return { ...prevLoadingMap, [type + "Tile"]: false };
        });
      });
  };

  const getBasicFilterValues = () => {
    let filterNames = {};
    DashboardAPI.getFilterNames()
      .then((res) => {
        filterNames = res.data["dashboard_common"];
        let filterNamesMapping = {};
        let filterMapping = {};
        filterNames.map((filter) => {
          if (filter.type != "Date" || filter.type != "Datetime") {
            filterNamesMapping[filter.name] = [];
          }
          filterMapping[filter.name] = filter.value;
        });
        setBasicFilter((prev) => {
          return { ...filterNamesMapping, ...prev };
        });
        setTileFilter((prev) => {
          return { ...filterNamesMapping, ...prev };
        });
        setGlobalState("mapping", (prevState) => {
          return { ...prevState, ...filterMapping };
        });

        return filterNames;
      })
      .then((filterNames) => {
        filterNames.map((filterName) => {
          DashboardAPI.getFilterValues({ filter_name: filterName.value }).then(
            (res) => {
              let tempData = res.data;
              tempData.push({ name: "All", value: "All" });
              setBasicFilterOptions((prevFilterOptions) => {
                return { ...prevFilterOptions, [filterName.name]: tempData };
              });
            }
          );
        });
      });
  };

  const getGroupByOptions = () => {
    const dynamicFunction = DashboardAPI[`getTrendGroupBy`];
    dynamicFunction().then((res) => {
      if (res.status == 200) {
        setTrendGroupByOptions(res.data);
      }
    });
  };

  const handleBasicFilterChange = (event, type = null) => {
    let { name, value } = event.target;

    if (
      Object.prototype.toString.call(value) === "[object Date]" ||
      type == "Date"
    ) {
      value = format(value, "yyyy-MM-dd");
    } else if (typeof value == typeof [] && value.length > 0) {
      let index = value.length - 1;
      let tempVal = value[index];
      if (tempVal.value == "All") {
        value = [];
      }
    }

    let tempBasicFilter = {
      ...basicFilter,
      [name]: value,
    };
    setBasicFilter(tempBasicFilter);
  };

  const handleTileFilterChange = (event, type = null) => {
    let { name, value } = event.target;

    if (
      Object.prototype.toString.call(value) === "[object Date]" ||
      type == "Date"
    ) {
      value = format(value, "yyyy-MM-dd");
    } else if (typeof value == typeof [] && value.length > 0) {
      let index = value.length - 1;
      let tempVal = value[index];
      if (tempVal.value == "All") {
        value = [];
      }
    }

    let tempTileFilter = {
      ...tileFilter,
      [name]: value,
    };
    setTileFilter(tempTileFilter);
  };

  const resetData = async (type) => {
    if (type == "Tile") {
      setOverallData({
        OTCBASE: [{}],
        OTCDSADAKAR: [{}],
        OTDBASE: [{}],
        OTDPHC: [{}],
        VFR: [{}, {}, {}],
      });
    } else {
      setTrendsData(dataMap);
    }
    return true;
  };

  const getAllOverallData = async () => {
    let temp = await resetData("Tile");
    Object.keys(dataMap).map((infoType) => {
      getOverallData(infoType);
    });
  };

  const getAllTrendData = async () => {
    let temp = await resetData("Trend");
    Object.keys(dataMap).map((infoType) => {
      getData(infoType);
    });
  };

  const convertToParams = (params) => {
    let tempParams = {};
    if (params) {
      Object.keys(params).forEach((param) => {
        if (params[param]) {
          let tempValue = params[param];
          if (typeof tempValue == typeof []) {
            tempValue = tempValue.map((tempValue) => {
              return tempValue.value;
            });
          }
          tempParams[filterNameMapping[param]] = tempValue;
        }
      });
    }
    return tempParams;
  };

  useEffect(() => {
    getBasicFilterValues();
    getGroupByOptions();
    getAllTrendData();
    getAllOverallData();
  }, []);

  return (
    <MDBox py={3}>
      <Grid container spacing={6}>
        <MDBox
          sx={{ width: "100%", marginBottom: "-1rem", zIndex: "10" }}
          onClick={() => {
            setFilterType("Tile");
            setShowFilters(!showFilters);
          }}
        >
          <Tooltip title={"Tile Filters"}>
            <FontAwesomeIcon
              style={{
                float: "right",
                fontSize: "medium !important",
                cursor: "pointer",
              }}
              icon={faFilter}
              className="navbar-icon"
            ></FontAwesomeIcon>
          </Tooltip>
        </MDBox>

        {/* <Grid container spacing={6}>
          <Grid marginLeft={6} item xs={12}>
            <Card>
              <MDBox p={3}>
                <Grid container spacing={3}>
                  {basicFilter &&
                    Object.keys(basicFilter).map((filterName) => {
                      if (!dateFilters.includes(filterName)) {
                        let ControlType = Controls["DropdownSingleSelect"];
                        return (
                          <>
                            <Grid
                              item
                              xs={12 / Object.keys(basicFilter).length}
                            >
                              <FormControl
                                sx={{
                                  marginBottom: "1rem",
                                }}
                                fullWidth
                              >
                                {basicFilterOptions[filterName] && (
                                  <ControlType
                                    name={filterName}
                                    label={filterName}
                                    value={basicFilter[filterName]}
                                    options={basicFilterOptions[filterName]}
                                    // label={<MDBox></MDBox>}
                                    onChange={(e) => handleBasicFilterChange(e)}
                                    size={"small"}
                                  />
                                )}
                              </FormControl>
                            </Grid>
                          </>
                        );
                      } else {
                        return (
                          <>
                            <Grid
                              item
                              xs={12 / Object.keys(basicFilter).length}
                            >
                              <FormControl
                                size={"large"}
                                sx={{
                                  marginBottom: "1rem",
                                }}
                                fullWidth
                              >
                                {[1].map(() => {
                                  let ControlType = Controls["Date"];
                                  return (
                                    <ControlType
                                      name={filterName}
                                      label={filterName}
                                      value={basicFilter[filterName]}
                                      onChange={(e) => {
                                        handleBasicFilterChange(e, "Date");
                                      }}
                                    />
                                  );
                                })}
                              </FormControl>
                            </Grid>
                          </>
                        );
                      }
                    })}
                </Grid>
              </MDBox>
            </Card>
          </Grid>
        </Grid> */}

        {/* Overall Data */}

        {
          <Grid
            container
            spacing={3}
            xs={12}
            md={12}
            mt={0.5}
            ml={3}
            sx={{ display: "flex", justifyContent: "space-evenly" }}
          >
            {overallData &&
              Object.keys(overallData).map((type, index) => {
                return overallData[type].map((card) => {
                  return (
                    <Grid item xs={12} md={6} lg={12 / 4}>
                      <MDBox mb={1.5}>
                        <AnalyticsCard
                          color="dark"
                          icon="equalizer"
                          count={card.value || "0"}
                          title={card.name}
                          width={`100%`}
                          isLoading={isLoadingMap[type + "Tile"]}
                        />
                      </MDBox>
                    </Grid>
                  );
                });
              })}
          </Grid>
        }

        <MDBox
          sx={{ width: "100%", marginBottom: "-1rem", zIndex: "10" }}
          onClick={() => {
            setFilterType("Trend");
            setShowFilters(!showFilters);
          }}
        >
          <Tooltip title={"Trend Filters"}>
            <FontAwesomeIcon
              style={{
                float: "right",
                fontSize: "medium !important",
                cursor: "pointer",
              }}
              icon={faFilter}
              className="navbar-icon"
            ></FontAwesomeIcon>
          </Tooltip>
        </MDBox>

        {/* Visualisation */}
        <Grid
          item
          xs={12}
          sx={{
            alignItems: "center",
            justifyContent: "center",
            marginTop: "-3rem",
          }}
        >
          {/* <Card sx={{ marginTop: "1rem", marginBottom: "4rem" }}> */}
          <MDBox>
            <Grid
              item
              spacing={3}
              display="flex"
              flexDirection={"column !important"}
              width={"100%"}
            >
              {/* <MDBox
                  mx={2}
                  mt={-3}
                  py={3}
                  px={2}
                  variant="gradient"
                  bgColor="info"
                  borderRadius="lg"
                  coloredShadow="info"
                >
                  <MDTypography
                    variant="h6"
                    color="white"
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <div>Visualisation</div>
                  </MDTypography>
                </MDBox> */}
              <MDBox>
                {trendsData &&
                  Object.keys(trendsData).map((type, index) => {
                    return (
                      <MDBox sx={{ padding: "2rem" }} key={index}>
                        <BaseChart
                          data={trendsData[type].data}
                          chartType={trendsData[type].chart_type}
                          title={trendsData[type].title}
                          isLoading={isLoadingMap[type + "Trend"]}
                        />
                      </MDBox>
                    );
                  })}
              </MDBox>
            </Grid>
          </MDBox>
          {/* </Card> */}
        </Grid>
      </Grid>

      {/* Filters and Groupby */}
      {showFilters && (
        <DialogWrapper
          width={"30%"}
          handleClose={() => {
            setShowFilters(!showFilters);
          }}
        >
          <MDBox
            sx={{
              fontWeight: "400",
              paddingBottom: "0.5rem",
              justifyContent: "center",
              textAlign: "center",
              fontSize: "x-large !important",
            }}
            className={"font-unset"}
          >
            {filterType} Filters
          </MDBox>
          <MDBox sx={{ fontWeight: "500", paddingBottom: "0.5rem" }}>
            Filters
          </MDBox>
          <Grid container spacing={3}>
            {filterType == "Trend" &&
              basicFilter &&
              Object.keys(basicFilter).map((filterName) => {
                if (!dateFilters.includes(filterName)) {
                  let ControlType = Controls["DropdownMultiSelect"];
                  return (
                    <>
                      <Grid item xs={6}>
                        <FormControl
                          sx={{
                            marginBottom: "1rem",
                          }}
                          fullWidth
                        >
                          {basicFilterOptions[filterName] && (
                            <ControlType
                              name={filterName}
                              label={filterName}
                              value={basicFilter[filterName]}
                              options={basicFilterOptions[filterName]}
                              // label={<MDBox></MDBox>}
                              onChange={(e) => handleBasicFilterChange(e)}
                              size={"small"}
                            />
                          )}
                        </FormControl>
                      </Grid>
                    </>
                  );
                } else {
                  return (
                    <>
                      <Grid item xs={6}>
                        <FormControl
                          size={"large"}
                          sx={{
                            marginBottom: "1rem",
                          }}
                          fullWidth
                        >
                          {[1].map(() => {
                            let ControlType = Controls["Date"];
                            return (
                              <ControlType
                                name={filterName}
                                label={filterName}
                                value={basicFilter[filterName]}
                                onChange={(e) => {
                                  handleBasicFilterChange(e, "Date");
                                }}
                              />
                            );
                          })}
                        </FormControl>
                      </Grid>
                    </>
                  );
                }
              })}

            {filterType == "Tile" &&
              tileFilter &&
              Object.keys(tileFilter).map((filterName) => {
                if (!dateFilters.includes(filterName)) {
                  let ControlType = Controls["DropdownMultiSelect"];
                  return (
                    <>
                      <Grid item xs={6}>
                        <FormControl
                          sx={{
                            marginBottom: "1rem",
                          }}
                          fullWidth
                        >
                          {basicFilterOptions[filterName] && (
                            <ControlType
                              name={filterName}
                              label={filterName}
                              value={tileFilter[filterName]}
                              options={basicFilterOptions[filterName]}
                              // label={<MDBox></MDBox>}
                              onChange={(e) => handleTileFilterChange(e)}
                              size={"small"}
                            />
                          )}
                        </FormControl>
                      </Grid>
                    </>
                  );
                } else {
                  return (
                    <>
                      <Grid item xs={6}>
                        <FormControl
                          size={"large"}
                          sx={{
                            marginBottom: "1rem",
                          }}
                          fullWidth
                        >
                          {[1].map(() => {
                            let ControlType = Controls["Date"];
                            return (
                              <ControlType
                                name={filterName}
                                label={filterName}
                                value={tileFilter[filterName]}
                                onChange={(e) => {
                                  handleTileFilterChange(e, "Date");
                                }}
                              />
                            );
                          })}
                        </FormControl>
                      </Grid>
                    </>
                  );
                }
              })}
          </Grid>
          {filterType == "Trend" && (
            <>
              <MDBox
                sx={{
                  fontWeight: "500",
                  paddingBottom: "0.5rem",
                  marginTop: "0.5rem",
                  paddingTop: "1rem",
                }}
              >
                Group By
              </MDBox>
              <FormControl
                sx={{
                  marginBottom: "1rem",
                }}
                fullWidth
              >
                <CustomAutoComplete
                  name={"Group By"}
                  label={"Group By"}
                  value={trendGroupBy.name}
                  options={trendGroupByOptions}
                  // label={<MDBox></MDBox>}
                  onChange={(e) => {
                    setTrendGroupBy(e.target.value);
                  }}
                  size={"small"}
                />
              </FormControl>
            </>
          )}

          <MDButton
            color={"info"}
            sx={{
              width: "90%",
              position: "absolute",
              bottom: "1rem",
              left: "50%",
              transform: "translate(-50%,0)",
            }}
            onClick={filterType == "Tile" ? getAllOverallData : getAllTrendData}
          >
            Apply
          </MDButton>
        </DialogWrapper>
      )}
    </MDBox>
  );
}

export default MainDashboard;
