import MDBox from "components/MDBox";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

// prop-types is library for typechecking of props

// @mui material components

//  React components
import MDTypography from "components/MDTypography";


// @mui material components
import Divider from "@mui/material/Divider";

//  React components



function AnalyticsCardSkeleton(props) {
  return (
    <SkeletonTheme highlightColor="white" {...props}>
      <MDBox display="flex" justifyContent="center" pt={1} px={2} mb={-1}>
        <MDBox
          textAlign="center"
          justifyContent={"center"}
          lineHeight={2}
          fontSize={"large !important"}
          className={"font-unset"}
        >
          <MDBox sx={{ fontSize: "1.2rem !important", fontWeight: "bold" }}>
            <Skeleton height={"1rem"} width={"3rem"}/>
          </MDBox>
        </MDBox>
      </MDBox>
      <Divider />
      <MDBox pb={2} px={2}>
        <MDTypography
          component="p"
          variant="button"
          color="text"
          display="flex"
          justifyContent="center"
        >
          <MDTypography variant="button" fontWeight="light" color="text">
            <Skeleton height={"1rem"} width={"5rem"}/>
          </MDTypography>
        </MDTypography>
      </MDBox>
    </SkeletonTheme>
  );
}

export default AnalyticsCardSkeleton;
