import React from 'react'
import { TextField } from '@material-ui/core';
import {InputAdornment, Typography} from '@mui/material';
import { toDecimalMark } from '../../utils/conversion';

export default function Integer({name, label, value,error=null, onChange,disabled,...props}) {


    return (
        <TextField
            variant="outlined"
            label={
                label?<Typography variant="headline" sx={{fontSize:"1rem !important",fontWeight:"500" }} className={"font-unset"}> {label} </Typography>:""
              }
            name={name}
            value={toDecimalMark(value)}
            onChange={onChange}
            disabled={disabled}
            {...(error && {error:true,helperText:error})}
            {...props}
        />
    )
}
