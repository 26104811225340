import React from "react";
import { TextField } from "@material-ui/core";
import { InputAdornment } from "@mui/material";

export default function Paragraph({ name, label, value, error = null, onChange, disabled=false, unit=false ,sx={},...props}) {
  return (
    <TextField
      variant="outlined"
      label={label}
      name={name}
      value={value}
      InputProps={
        unit
          ? {
              startAdornment: <InputAdornment>ZAR</InputAdornment>,
            }
          : {}
      }
      onChange={onChange}
      sx={{...sx,fontSize:"small"}}
      disabled={disabled}
      multiline
      minRows={1}
      
      {...(error && { error: true, helperText: error })}
      {...props}
    />
  );
}
