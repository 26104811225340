// @mui material components

//  React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
//  React example components
import Card from "@mui/material/Card";
import { setGlobalState } from "Global";
import MDButton from "components/MDButton";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import { useNavigate } from "react-router-dom";
// Data

// Dashboard components
import { TabContext } from "@mui/lab";
import {
  AppBar,
  FormControl,
  Grid,
  Icon,
  InputLabel,
  MenuItem,
  Select,
  Tab,
  Tabs,
} from "@mui/material";
import DataTable from "examples/Tables/DataTable";
import { useEffect, useState } from "react";
import LoadingOverlay from "react-loading-overlay";

import AccessWrapper from "wrappers/accessWrapper";

import { ExcelDataAPI } from "api";
import useExcelData from "./ExcelData";
import { column, whitespace } from "stylis";

function Bulk(props) {
  const navigation = useNavigate();

  const [tabValue, setTabValue] = useState("0");
  const [tableValue, setTableValue] = useState("Success");
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingSuccess, setIsLoadingSuccess] = useState(false);
  const [isLoadingFail, setIsLoadingFail] = useState(false);
  const [excelData, setExcelData] = useState({});
  const [selectedReference, setSelectedReference] =
    useState("delivery_otd_base");
  const [expectedColumns, setExpectedColumns] = useState({});
  const [referenceList, setReferenceList] = useState([]);
  const [filters, setFilters] = useState({
    limit: 50,
    offset: 0,
  });
  const [updateFlag, setUpdateFlag] = useState(false);

  const [successRows, successColumns, successPageInfo] = useExcelData({
    setIsLoading: setIsLoadingSuccess,
    reference: selectedReference,
    dataAPI: "Success",
    filters: filters,
  });

  const [failRows, failColumns, FailPageInfo] = useExcelData({
    setIsLoading: setIsLoadingFail,
    reference: selectedReference,
    dataAPI: "Fail",
    filters: filters,
  });

  useEffect(() => {
    setExcelData({
      Success: { rows: successRows, columns: successColumns },
      Fail: { rows: failRows, columns: failColumns },
    });
  }, [successRows, failRows, failColumns, successColumns]);

  const handleFilterChange = (event) => {
    let tempFilters = { ...filters, [event.target.name]: event.target.value };
    if (event.target.name == "limit") {
      tempFilters["offset"] = 0;
    }
    setFilters(tempFilters);
  };

  const convertKeyToName = (key) => {
    let name = key
      .split("_")
      .map((word) => {
        return word[0].toUpperCase() + word.slice(1);
      })
      .join(" ");
    return name;
  };

  const handleExcelFileChange = async (event) => {
    setIsLoading(true);
    let tempSelectedReference = selectedReference;
    const formdata = new FormData();
    formdata.append(event.target.name, event.target.files[0]);
    setSelectedReference(null);
    ExcelDataAPI.excelUpload(
      { reference: selectedReference, update: updateFlag },
      formdata
    )
      .then((res) => {
        if (res.status == 200) {
          setGlobalState("error", {
            open: true,
            message: "Successfully uploaded the File",
            type: "success",
          });
          setSelectedReference(tempSelectedReference);
          // Get Success and Fail Tables
          setIsLoading(false);
          event.target.value = null;
        }
      })
      .catch((error) => {
        event.target.value = null;
        setSelectedReference(tempSelectedReference);
        setIsLoading(false);
      });
  };

  const getReferenceList = () => {
    ExcelDataAPI.getReferences()
      .then((res) => {
        if (res.status == 200) {
          setReferenceList(res.data);
          setSelectedReference(res.data[0].value);
          setIsLoading(false);
          let tempColumns = {};
          res.data.map((reference) => {
            tempColumns[reference.value] = reference.columns_expected;
          });
          setExpectedColumns(tempColumns);
        }
      })
      .catch((error) => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getReferenceList();
  }, []);

  return (
    <DashboardLayout>
      {/* <DashboardNavbar user={props?.user} /> */}
      <LoadingOverlay active={isLoading} spinner text="Loading your content...">
        <TabContext value={selectedReference}>
          <AppBar position="static" sx={{ margin: "20px 30px 10px 0px" }}>
            <Tabs
              orientation={"horizontal"}
              value={selectedReference}
              onChange={(e, value) => {
                setSelectedReference(value);
              }}
            >
              {referenceList.map((reference) => {
                return (
                  <Tab
                    label={reference.name}
                    icon={
                      <Icon fontSize="small" sx={{ mt: -0.25 }}>
                        dataset
                      </Icon>
                    }
                    value={reference.value}
                  />
                );
              })}
            </Tabs>
          </AppBar>

          <MDBox pt={3}>
            <LoadingOverlay
              active={
                tableValue == "Success" ? isLoadingSuccess : isLoadingFail
              }
              spinner
              text="Loading your content..."
            >
              <Card sx={{ marginBottom: "2rem" }}>
                <MDBox
                  sx={{
                    alignItems: "center",
                    height: "auto",
                    maxWidth: "100%",
                    marginBottom: "1rem",
                    minHeight: "15vh",
                  }}
                >
                  <MDBox
                    sx={{
                      marginTop: "-1rem",
                    }}
                  >
                    <AppBar position="static">
                      <Tabs
                        orientation={"horizontal"}
                        value={tableValue}
                        onChange={(event, value) => setTableValue(value)}
                      >
                        <Tab label="Success" value="Success" />
                        <Tab label="Failure" value="Fail" />
                      </Tabs>
                    </AppBar>
                  </MDBox>
                  <MDBox
                    sx={{
                      position: "relative",
                    }}
                  >
                    {excelData[tableValue] && (
                      <DataTable
                        table={{
                          columns: excelData[tableValue].columns,
                          rows: excelData[tableValue].rows,
                        }}
                        isSorted={true}
                        canSearch={true}
                        showTotalEntries={true}
                        noEndBorder
                        // filters={filters}
                        // handleFilterChange={handleFilterChange}
                        customPagination={true}
                        ignoreFilterList={["limit", "offset"]}
                        pageInfo={
                          tableValue == "Success"
                            ? successPageInfo
                            : FailPageInfo
                        }
                        // downloadFileName={`Excel ${tableValue} Data`}
                      />
                    )}
                  </MDBox>
                </MDBox>
              </Card>
            </LoadingOverlay>
          </MDBox>
        </TabContext>
        <MDBox>
          <b>Required Columns in Excel Upload:</b>
          <MDBox sx={{ display: "flex", marginBottom: "0.5rem" }}>
            {expectedColumns[selectedReference] &&
              expectedColumns[selectedReference].map((column) => {
                return <>{column}, &nbsp;</>;
              })}
          </MDBox>
        </MDBox>
        <Card sx={{ height: "100%" }}>
          <MDBox
            p={3}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <MDTypography variant="h6" fontWeight="medium">
              {selectedReference && convertKeyToName(selectedReference)} File
              Upload
            </MDTypography>
            <MDBox sx={{ display: "flex", flexDirection: "row" }}>
              <Grid item sx={{ minWidth: "8rem", marginRight: "1rem" }}>
                <FormControl fullWidth>
                  <InputLabel id="nextStep-label">
                    Enable Bulk Update
                  </InputLabel>
                  <Select
                    id="stateChange"
                    labelId="nextStep-label"
                    value={updateFlag}
                    name="updateFlag"
                    label={"Enable Bulk update"}
                    onChange={() => {
                      setUpdateFlag(!updateFlag);
                    }}
                    sx={{ marginLeft: "2", width: "100%", minHeight: "2rem" }}
                  >
                    <MenuItem
                      sx={{ width: "100%", minHeight: "2rem" }}
                      value={true}
                    >
                      Yes
                    </MenuItem>
                    <MenuItem
                      sx={{ width: "100%", minHeight: "2rem" }}
                      value={false}
                    >
                      No
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <label htmlFor="file">
                <input
                  type="file"
                  onChange={(e) => handleExcelFileChange(e)}
                  name="file"
                  id="file"
                  style={{ display: "none" }}
                />
                <MDButton
                  variant="outlined"
                  color="info"
                  size="small"
                  component="span"
                >
                  Upload Document
                </MDButton>
              </label>
            </MDBox>
          </MDBox>
        </Card>
      </LoadingOverlay>
    </DashboardLayout>
  );
}
export default AccessWrapper(Bulk, ["agent", "superuser", "admin"]);
