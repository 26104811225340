import { Card } from "@mui/material";
import MDBox from "components/MDBox";
import CustomAutoComplete from "components/controls/AutoComplete";
import React, { useEffect, useState } from "react";
import { Chart } from "react-google-charts";
import ChartSkeleton from "skeletons/dashboard/chartSkeleton";
import { convertDataTypes } from "utils/conversion";
import EmptyResult from "utils/emptyResult";

export const defaultData = [
  ["Year", "Sales", "Expenses"],
  ["2013", 1000, 400],
  ["2014", 1170, 460],
  ["2015", 660, 1120],
  ["2016", 1030, 540],
];

const chartTypes = [
  "AreaChart",
  "Bar",
  "BarChart",
  "BubbleChart",
  "CandlestickChart",
  "ColumnChart",
  "ComboChart",
  "DonutChart",
  "GanttChart",
  "GaugeChart",
  "GeoChart",
  "Histogram",
  "Line",
  "LineChart",
  "OrgChart",
  "PieChart",
  "SankeyDiagram",
  "ScatterChart",
  "SteppedAreaChart",
  "TableChart",
  "Timeline",
  "TreeMap",
  "WaterfallChart",
];

export function BaseChart({
  chartType = "Line",
  data = [],
  title = "Line Chart",
  options = {},
  isLoading = false,
  ...props
}) {
  const [chartSelected, setChartSelected] = useState(chartType);
  const [chartData, setChartData] = useState(null);

  const handleChartSelected = (event) => {
    let { name, value } = event.target;
    setChartSelected(value);
  };

  const convertDataForGoogleCharts = (data) => {
    // Extract keys from the first dictionary
    const keys = Object.keys(data[0]);

    // Initialize an array to store the converted data
    let convertedData = [];

    // Push keys as the first element of the convertedData array
    convertedData.push(keys);

    // Loop through each dictionary in the list
    data.forEach((item) => {
      // Initialize an array to store each row of data
      let rowData = [];

      // Loop through each key in the dictionary
      keys.forEach((key) => {
        // Push the value corresponding to the key to rowData
        rowData.push(item[key]);
      });

      // Push the rowData array to convertedData
      convertedData.push(rowData);
    });

    return convertedData;
  };

  useEffect(() => {
    if (data?.length > 0) {
      setChartData(data);
    }
  }, [data]);

  return (
    <Card
      sx={{ height: "100%", width: "100%", border: "0.5px solid lightgrey" }}
    >
      {/* <MDBox sx={{  display: "flex", justifyContent: "end" }}>
        <CustomAutoComplete
          value={chartSelected}
          name={"Chart Type"}
          label={"Chart Type"}
          options={chartTypes}
          onChange={handleChartSelected}
          size={"small"}
        />
      </MDBox> */}

      {isLoading && <ChartSkeleton />}

      <>
        <MDBox
          sx={{
            textAlign: "center",
            fontSize: "medium !important",
            padding: "0.5rem",
            fontWeight: "500",
            paddingTop: "1rem",
          }}
          className={"font-unset"}
        >
          {title}
        </MDBox>
        {isLoading == false && data.length == 0 && (
          <MDBox sx={{ width: "100%", height: "20rem" }}>
            <EmptyResult />
          </MDBox>
        )}
        {isLoading == false && data.length > 0 && (
          <Chart
            chartType={chartSelected}
            width="100%"
            height={props.height || "100%"}
            data={chartData}
            options={options}
            {...props}
            style={{ padding: "1rem" }}
          />
        )}
      </>
    </Card>
  );
}
