import App from "App";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import "react-loading-skeleton/dist/skeleton.css";
import { MaterialUIControllerProvider } from "context";
import "css/main.css";



const AppWithAuthentication = () => (
  <BrowserRouter>
    <MaterialUIControllerProvider>
      <App />
    </MaterialUIControllerProvider>
  </BrowserRouter>
);

ReactDOM.render(<AppWithAuthentication />, document.getElementById("root"));

