import React, { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
// import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import Button from "components/controls/Button";
import { basicAPI, client } from "api";
import { setGlobalState } from "Global";

export default function CustomLogin() {
  const [searchParams] = useSearchParams();

  // const isAuthenticated = useIsAuthenticated();

  const navigation = useNavigate();

  // const { instance } = useMsal();

  // const initializeSignIn = () => {
  //   instance.loginRedirect({
  //     scopes:['user.read']
  //   });
  // };

  useEffect(() => {
    basicAPI
      .loginCallback(searchParams.get("code"))
      .then((res) => {
        sessionStorage.setItem("token", `Bearer ${res.data.access_token}`);
        client.defaults.headers.common.Authorization = `${sessionStorage.getItem(
          "token"
        )}`;
        sessionStorage.setItem("user", JSON.stringify(res.data));
        navigation("/");
      })
      .catch((error) => {});
  }, []);

  return (
    <>
      <Button>Signin</Button>
    </>
  );
}
