// @mui material components
import Card from "@mui/material/Card";

//  React components
import MDBox from "components/MDBox";

//  React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";

import DataTable from "examples/Tables/DataTable";
// Data
import { setGlobalState } from "Global";
import { ExcelDataAPI } from "api";

import { useEffect, useState } from "react";
import LoadingOverlay from "react-loading-overlay";
import { useNavigate } from "react-router-dom";
//  React example components
// Data

// Dashboard components
import { TabContext } from "@mui/lab";
import { AppBar, Icon, Tab, Tabs } from "@mui/material";
import useExcelData from "./data/excelData";
import { DashboardAPI } from "api";
import { chartToFilterMapping } from "utils/mapping";
import { useGlobalState } from "Global";
import { format } from "date-fns";

function Tables(props) {
  const [isLoading, setIsLoading] = useState(true);
  const [deliveryData, setDeliveryData] = useState([]);
  const [selectedReference, setSelectedReference] =
    useState("delivery_otd_base");
  const [referenceList, setReferenceList] = useState([]);
  const [filters, setFilters] = useState({
    delivery_otc: {
      limit: 50,
      offset: 0,
    },
    delivery_otd: {
      limit: 50,
      offset: 0,
    },
    zcvr: {
      limit: 50,
      offset: 0,
    },
    daily_picking_tracker: {
      limit: 50,
      offset: 0,
    },
    customer_mapping: {
      limit: 50,
      offset: 0,
    },
  });
  const [filterOptions, setFilterOptions] = useState({});
  const [filterTypeMapping, setFilterTypeMapping] = useState({});
  const [filterMapping] = useGlobalState("mapping");
  const navigation = useNavigate();

  const handleDeleteRow = (reference, id) => {
    ExcelDataAPI.deleteMainRecord({ reference: reference, id: id })
      .then((res) => {
        if (res.status == 200) {
          setGlobalState("error", {
            open: true,
            message: `Deleted the record with ID -${id} successfully`,
            type: "success",
          });
        }
      })
      .catch((error) => {
        setIsLoading(false);
      });
  };

  const [rows, columns, pageInfo, onClick, updatedRows] = useExcelData({
    setIsLoading: setIsLoading,
    reference: selectedReference,
    dataAPI: "Main",
    filters: filters[selectedReference],
    handleDeleteRow: handleDeleteRow,
    filterTypeMapping: filterTypeMapping,
    filterMapping: filterMapping,
  });

  const [globalFilterOptions] = useGlobalState("filterOptions");

  const getBasicFilterValues = () => {
    let filterNames = {};
    DashboardAPI.getFilterNames()
      .then((res) => {
        filterNames = res.data[`${selectedReference}_main`];
        let tempFilterTypeMapping = {};
        let filterNamesMap = { limit: 50, offset: 0 };
        let filterMapping = {};
        filterNames.map((filter) => {
          if (filter.type != "Date" && filter.type != "Datetime") {
            filterNamesMap[filter.name] = [];
          } else {
            filterNamesMap[filter.name] = "";
          }
          tempFilterTypeMapping[filter.name] = filter.type;
          filterMapping[filter.name] = filter.value;
        });
        setFilters((prevFilters) => {
          return { ...prevFilters, [selectedReference]: filterNamesMap };
        });
        setFilterTypeMapping((prevFilterTypeMapping) => {
          return { ...prevFilterTypeMapping, ...tempFilterTypeMapping };
        });
        setGlobalState("mapping", (prevState) => {
          return { ...prevState, ...filterMapping };
        });

        return filterNames;
      })
      .then((filterNames) => {
        filterNames.map((filterName) => {
          DashboardAPI.getFilterValues({ filter_name: filterName.value }).then(
            (res) => {
              let tempData = res.data;
              tempData.push({ name: "All", value: "All" });
              setFilterOptions((prevFilterOptions) => {
                let newFilterOptions = {
                  ...prevFilterOptions,
                  [filterName.name]: tempData,
                };
                return newFilterOptions;
              });
              setGlobalState("filterOptions", (prevFilterOptions) => {
                return {
                  ...prevFilterOptions,
                  [selectedReference]: {
                    ...prevFilterOptions[selectedReference],
                    [filterName.name]: tempData,
                  },
                };
              });
            }
          );
        });
      });
  };

  const updateRecords = () => {
    ExcelDataAPI.updateMainRecords(
      { reference: selectedReference },
      Object.values(updatedRows)
    )
      .then((res) => {
        if (res.status == 200) {
          setGlobalState("error", {
            open: true,
            message: `Successfully updated the records`,
            type: "success",
          });
          setFilters((prevFilters) => {
            return {
              ...prevFilters,
              [selectedReference]: {
                ...prevFilters[selectedReference],
                [new Date().toDateString()]: "1",
              },
            };
          });
        }
      })
      .catch((error) => {
        setIsLoading(false);
      });
  };

  const getReferenceList = () => {
    ExcelDataAPI.getReferences()
      .then((res) => {
        if (res.status == 200) {
          setReferenceList(res.data);
          setSelectedReference(res.data[0].value);
          setIsLoading(false);
        }
      })
      .catch((error) => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    // getBasicFilterValues();
    getReferenceList();
  }, []);

  useEffect(() => {
    if (!globalFilterOptions[selectedReference]) {
      getBasicFilterValues();
    }
  }, [selectedReference]);

  const handleFilterChange = (event) => {
    if (
      typeof event.target.value == typeof [] &&
      event.target.value.length > 0
    ) {
      let index = event.target.value.length - 1;
      let value = event.target.value[event.target.value.length - 1];
      if (typeof value == typeof {}) {
        event.target.value[index] = value.email_id
          ? value.email_id
          : value._id
          ? value._id
          : value;
      }
    }

    // if (type == "Date") {
    //   value = format(value, "yyyy-MM-dd");
    // } else if (type == "Integer") {
    //   if (value) {
    //     let newVal = toFloatMark(value);
    //     if (value.slice(-1) == ".") {
    //       value = newVal + ".";
    //     } else if (value.slice(-2) == ".0") {
    //       value = newVal + ".0";
    //     } else {
    //       value = newVal;
    //     }
    //   }
    // }
    if (
      Object.prototype.toString.call(event.target.value) === "[object Date]"
    ) {
      event.target.value = format(event.target.value, "yyyy-MM-dd 00:00:00");
    } else if (
      typeof event.target.value == typeof [] &&
      event.target.value.includes("All")
    ) {
      event.target.value = [];
    }
    let tempFilters = {
      ...filters,
      [selectedReference]: {
        ...filters[selectedReference],
        [event.target.name]: event.target.value,
      },
    };

    // If limit is changes reset offset to 0
    if (event.target.name == "limit") {
      tempFilters["offset"] = 0;
    }

    setFilters(tempFilters);
  };

  const capitalize = (word) => {
    return word.charAt(0).toUpperCase() + word.slice(1);
  };

  return (
    <DashboardLayout>
      {/* <DashboardNavbar user={props?.user} /> */}
      <LoadingOverlay active={false} spinner text="Loading your content...">
        <TabContext value={selectedReference}>
          <AppBar position="static" sx={{ margin: "20px 30px 10px 0px" }}>
            <Tabs
              orientation={"horizontal"}
              value={selectedReference}
              onChange={(e, value) => {
                setSelectedReference(value);
              }}
            >
              {referenceList.map((reference) => {
                return (
                  <Tab
                    label={reference.name}
                    icon={
                      <Icon fontSize="small" sx={{ mt: -0.25 }}>
                        dataset
                      </Icon>
                    }
                    value={reference.value}
                  />
                );
              })}
            </Tabs>
          </AppBar>

          <MDBox pt={3}>
            {/* <LoadingOverlay
              active={isLoading}
              spinner
              text="Loading your content..."
            > */}
            <Card sx={{ marginBottom: "2rem" }}>
              <MDBox
                sx={{
                  alignItems: "center",
                  height: "auto",
                  maxWidth: "100%",
                  marginBottom: "1rem",
                  minHeight: "15vh",
                }}
              >
                <MDBox
                  sx={{
                    position: "relative",
                  }}
                >
                  {
                    <DataTable
                      table={{
                        columns: columns,
                        rows: rows,
                      }}
                      isSorted={true}
                      canSearch={true}
                      showTotalEntries={true}
                      noEndBorder
                      filters={
                        filters[selectedReference] == null
                          ? []
                          : filters[selectedReference]
                      }
                      filterOptions={filterOptions}
                      handleFilterChange={handleFilterChange}
                      customPagination={true}
                      ignoreFilterList={["limit", "offset"]}
                      pageInfo={pageInfo}
                      isLoading={isLoading}
                      rowClick={onClick}
                      updateButton={
                        Object.keys(updatedRows).length > 0
                          ? updateRecords
                          : null
                      }
                      // downloadFileName={`Excel ${tableValue} Data`}
                    />
                  }
                </MDBox>
              </MDBox>
            </Card>
            {/* </LoadingOverlay> */}
          </MDBox>
        </TabContext>
      </LoadingOverlay>
    </DashboardLayout>
  );
}

export default Tables;
