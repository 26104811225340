import { TableRow } from "@mui/material";
import DataTableHeadCell from "examples/Tables/DataTable/DataTableHeadCell";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

function HeaderSkeleton({ columns=10 }) {
  return (
    <SkeletonTheme highlightColor="white">
      {Array(1)
        .fill(0)
        .map((item, index) => {
          return (
            <TableRow key={index}>
              {Array(columns)
                .fill(0)
                .map((cell,index2) => (
                  <DataTableHeadCell key={index2}>
                    <Skeleton style={{ height: "1rem", width: "200px" }} />
                  </DataTableHeadCell>
                ))}
            </TableRow>
          );
        })}
    </SkeletonTheme>
  );
}

export default HeaderSkeleton;
