// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

//  React components
import MDBox from "components/MDBox";
import { useState } from "react";
import { Menu } from "@mui/material";
import NotificationItem from "examples/Items/NotificationItem";
import Icon from "@mui/material/Icon";
import { useNavigate } from "react-router-dom";

function DataTableBodyCell({
  noBorder,
  align,
  color,
  bgcolor,
  children,
  onclick,
  case_id,
}) {
  const [action, setAction] = useState(false);
  const navigation = useNavigate();
  const handleOpenAction = (event) => {
    setAction(event.target);
  };

  const handleCloseAction = () => {
    setAction(false);
  };

  return (
    <MDBox
      component="td"
      textAlign={align}
      py={1.5}
      px={3}
      sx={({
        palette: { light },
        typography: { size },
        borders: { borderWidth },
      }) => ({
        fontSize: size.sm,
        backgroundColor: bgcolor,
        color,
        cursor: "pointer",
        borderBottom: noBorder
          ? "none"
          : `${borderWidth[1]} solid ${light.main}`,
      })}
      onClick={(event) => {
        if (event.target.id == "action") {
          handleOpenAction(event);
        } else {
          onclick();
        }
      }}
    >
      <MDBox
        display="inline-block"
        width="max-content"
        color={color}
        sx={{ verticalAlign: "middle", cursor: "pointer" }}
      >
        {children}
        {action && (
          <Menu
            anchorEl={action}
            anchorReference={null}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            open={true}
            onClose={handleCloseAction}
            sx={{ mt: 2 }}
          >
            <NotificationItem
              icon={<Icon>history</Icon>}
              onClick={() => {
                // navigation('/history/' + case_id)
                window.open("/history/" + case_id, "_blank");
              }}
              title="History"
            />
            {/* <NotificationItem
              icon={<Icon>code</Icon>}
              onClick={() => {
                getBarCode(case_id)
              }}
              title="Generate QR"
            /> */}
          </Menu>
        )}
      </MDBox>
    </MDBox>
  );
}

// Setting default values for the props of DataTableBodyCell
DataTableBodyCell.defaultProps = {
  noBorder: false,
  align: "left",
};

// Typechecking props for the DataTableBodyCell
DataTableBodyCell.propTypes = {
  children: PropTypes.node.isRequired,
  noBorder: PropTypes.bool,
  align: PropTypes.oneOf(["left", "right", "center"]),
};

export default DataTableBodyCell;
