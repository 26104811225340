import React from "react";
import {
  FormControl,
  InputLabel,
  Select as MuiSelect,
  MenuItem,
  FormHelperText,
  Paper,
  makeStyles,
} from "@material-ui/core";
import {
  Autocomplete,
  Box,
  Chip,
  TextField,
  Tooltip,
  Typography,
  createFilterOptions,
} from "@mui/material";
import "css/controls/autocomplete.css";

const useStyles = makeStyles({
  popper: { minWidth: "max-content !important" },
});
const filter = createFilterOptions();

export default function CustomAutoCompleteMultiple({
  name,
  label,
  value,
  onChange,
  options,
  disabled,

  ...props
}) {
  const classes = useStyles();
  const selectedValues = Array.isArray(value) ? value : [value];
  return (
    <FormControl variant="outlined" disabled={disabled} fullWidth>
      <Autocomplete
        value={selectedValues}
        multiple
        onChange={(e, newValue) => {
          if (typeof newValue != 'object') {
            onChange({ target: { name: name, value: newValue } });
          } else if (typeof newValue == typeof [] ) {
            // Create a new value from the user input
            let value = newValue?.at(-1)
            onChange({ target: { name: name, value: newValue } });
          }
        }}
        size={"medium"}
        disabled={disabled}
        options={options}
        filterOptions={(options, params) => {
          const filtered = filter(options, params);
  
          const { inputValue } = params;
          // Suggest the creation of a new value
          const isExisting = options.some((option) => inputValue === option.name);
          if (inputValue !== '' && !isExisting) {
            filtered.push({
              value: inputValue,
              name: inputValue,
            });
          }
  
          return filtered;
        }}
        {...props}
        getOptionLabel={(option) => {
          if (typeof option === "string") {
            return option;
          } else if (option?.name) {
            return option.name;
          } else {
            return option;
          }
        }}
        classes={{ popper: classes.popper }}
        // PaperComponent={(props) => (
        //     <Paper
        //       sx={{
        //         fontSize: "25px",
        //       }}
        //       {...props}
        //     />
        //   )}
        renderInput={(params) => {
          return (
            <TextField
              {...params}
              label={
                label &&
                (props.size == "small" ? (
                  <Typography>{label}</Typography>
                ) : (
                  <Typography
                    variant="headline"
                    sx={{ fontSize: "1rem !important", fontWeight: "500" }}
                    className={"font-unset"}
                  >
                    {" "}
                    {label}{" "}
                  </Typography>
                ))
              }
              placeholder={label}
              InputProps={{
                ...params.InputProps,

                endAdornment: <></>,
              }}
              // {...props}
              sx={{ width: "100% !important", margin: "0 !important" }}
            />
          );
        }}
        renderTags={(value, getTagProps, ownerState) => {
          let showChips = value.slice(0, 2);
          if (ownerState.focused) {
            showChips = value;
          }
          return (
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                gap: 0.1,
                justifyContent: "left",
              }}
            >
              {showChips.map((chipValue, index) => {
                if (index < (ownerState.focused ? showChips.length : 1)) {
                  return (
                    <Tooltip title={chipValue.name} placement="bottom-start">
                      <Chip
                        key={chipValue.name}
                        label={chipValue.name}
                        sx={{
                          fontSize: "x-small",
                          height: "fit-content",
                        }}
                      />
                    </Tooltip>
                  );
                } else {
                  return (
                    <Chip
                      key={"+1"}
                      label={`+${showChips.length - 1}`}
                      sx={{
                        fontSize: "x-small",
                        height: "fit-content",
                      }}
                    />
                  );
                }
              })}
            </Box>
          );
        }}
        sx={{ width: "100% !important" }}
      />
    </FormControl>
  );
}
