// @mui material components

//  React components

// Images
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const TableReport = ({ excelData }) => {
  const navigation = useNavigate();

  const [excelRows, setExcelRows] = useState([]);

  const [excelColumns, setExcelColumns] = useState([]);

  const convertKeyToName = (key) => {
    let name = key
      .split("_")
      .map((word) => {
        return (word[0] ? word[0].toUpperCase() : "") + word.slice(1);
      })
      .join(" ");
    return name;
  };

  useEffect(() => {
    if (excelData?.length>0) {
      const tempRows = [];

      let initColumns =
        excelData && excelData.length > 0 ? Object.keys(excelData[0]) : [];

      excelData.map((row, key) => {
        let rows = {};
        for (const column of initColumns) {
          if (typeof row[column] == "object") {
            if (column != "Category and claim amount") {
              rows[column] = Object.keys(row[column]).filter((item) => {
                return row[column][item] == true;
              })[0];
            }
          } else {
            rows[column] = row[column];
          }
        }
        tempRows.push(rows);
      });

      initColumns = initColumns.map((column) => {
        return {
          Header: convertKeyToName(column),
          accessor: column,
          align: "center",
        };
      });

      setExcelColumns(initColumns);
      setExcelRows(tempRows);
    }else if(excelData?.length==0){
      setExcelColumns([]);
      setExcelRows([]);
    }
  }, [excelData]);

  return [excelRows, excelColumns];
};

export default TableReport;
