export const chartToFilterMapping = {
    StartDate: "start_timestamp",
    EndDate: "end_timestamp",
    Customer: "customer",
    Region: "region",
    OffloadingPoint: "offloading_point",
    DeliveryRegion: "delivery_region",
    RegionOrLane: "region_or_lane",
    CollectionOrDirect: "collection_or_direct",
    LocalOrExport: "local_or_export",
    CustomerType: "customer_type",
    PGDeliveryNumber: "pg_delivery_number",
    OrderNumber:"order_number",
    PrideNumber:"pride_number",
    PlanningDate:"planning_date",
    CustomerPoNumber:"customer_po_number",
    ShipTo:"ship_to",
    OrderType:"order_type",
    VehicleSize:"vehicle_size",
    OffloadingDate:"offloading_date",
    ActualOffloadingDate:"actual_offloading_date",
    OnTimeDelivery:"on_time_delivery",
    CollectionDate:"collection_date",
    ActualCollectionDate:"actual_collection_date",
    OnTimeCollection:"on_time_collection",
    limit:"limit",
    offset:"offset"
  };