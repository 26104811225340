// @mui material components

//  React components

// Images
import { setGlobalState } from "Global";
import { ExcelDataAPI } from "api";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Controls from "components/controls/Controls";
import { format } from "date-fns";
import { toFloatMark } from "utils/conversion";
import { chartToFilterMapping } from "utils/mapping";
import MDBox from "components/MDBox";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDeleteLeft, faTrash } from "@fortawesome/free-solid-svg-icons";
import { Tooltip } from "@mui/material";

const useExcelData = ({
  reference,
  setIsLoading,
  dataAPI,
  filters,
  handleDeleteRow,
  filterTypeMapping,
  filterMapping,
}) => {
  const navigation = useNavigate();
  const [excelData, setExcelData] = useState({});

  const [excelRows, setExcelRows] = useState([]);

  const [excelColumns, setExcelColumns] = useState([]);
  const [columnData, setColumnData] = useState([]);
  const [pageInfo, setPageInfo] = useState({});
  const [updatedRows, setUpdatedRows] = useState({});

  const handleInputChange = (e, type, index, edit, scope, keyscope) => {
    let { name, value } = e.target;
    if (type == "Date") {
      value = format(value, "yyyy-MM-dd");
    } else if (type == "Integer") {
      if (value) {
        let newVal = toFloatMark(value);
        if (value.slice(-1) == ".") {
          value = newVal + ".";
        } else if (value.slice(-2) == ".0") {
          value = newVal + ".0";
        } else {
          value = newVal;
        }
      }
    }

    let ControlType = Controls[type];
    let newField = (
      <ControlType
        name={name}
        key={name + index}
        size={"small"}
        value={value}
        onChange={(e) => handleInputChange(e, type, index)}
        disabled={edit}
        options={scope}
        keyscopes={keyscope}
        style={{
          borderRadius: "5px",
          lineHeight: "0.5 !important",
          // boxShadow: `2px 2px 4px rgba(0,0,0, 0.15)`,
          minHeight: "2rem",
        }}
        InputLabelProps={{
          style: {
            height: "1rem",
          },
          // disableUnderline: true,
        }}
        /* styles the input component */
        {...(type != "DropdownSingleSelect"
          ? {
              inputProps: {
                style: {
                  height: "1rem",
                  padding: "0.5rem 0.5rem",
                  textAlign: "center",
                },
              },
            }
          : {})}
        componentSize={"small"}
        className={"table-data"}
      />
    );

    // e.target.value=value

    setExcelRows((prevRows) => {
      let tempRows = [...prevRows];
      tempRows[index] = { ...tempRows[index], [name]: newField };
      setUpdatedRows((prevUpdatedRows) => {
        return {
          ...prevUpdatedRows,
          [tempRows[index]["_id"]]: {
            ...prevUpdatedRows[tempRows[index]["_id"]],
            _id: tempRows[index]["_id"],
            [name]: value,
          },
        };
      });
      return tempRows;
    });
  };
  const convertToParams = (params) => {
    let tempParams = {};
    if (params) {
      Object.keys(params).forEach((param) => {
        if (params[param]) {
          let tempValue = params[param];
          if (typeof tempValue == typeof []) {
            tempValue = tempValue.map((tempValue) => {
              return tempValue.value;
            });
          } else if (typeof tempValue == "string") {
            tempValue = [tempValue];
          }
          tempParams[filterMapping[param]] = convertType(
            tempValue,
            filterTypeMapping[param]
          );
        }
      });
    }
    return tempParams;
  };

  const convertType = (value, type = "Text") => {
    if (typeof value == "object") {
      let tempValue = [];
      tempValue = value.map((val) => {
        if (type == "Integer") {
          return parseInt(val);
        } else if (type == "Float") {
          return parseFloat(val);
        } else if (type == "Date" || type == "Datetime") {
          return val;
        } else {
          return val;
        }
      });
      return tempValue;
    } else {
      return value;
    }
  };

  const convertKeyToName = (key) => {
    let name = key
      .split("_")
      .map((word) => {
        if (word.length > 0) {
          return word[0].toUpperCase() + word.slice(1);
        } else {
          return word;
        }
      })
      .join(" ");

    return name.trim();
  };

  const fetchExcelAPI = async () => {
    setIsLoading(true);
    try {
      const dynamicFunction = ExcelDataAPI[`get${dataAPI}Table`];
      const res = await dynamicFunction(
        { reference: reference, ...filters },
        convertToParams(filters)
      );
      setPageInfo({ count: res.data.count, pages: res.data.pages });
      setExcelData({ columns: res.data.columns_data, data: res.data.data });
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
    }
  };

  const handleRowClick = (id, caseId, value, rowIndex, index) => {
    if (typeof value != "object" && excelData.columns[index].edit) {
      let columnInfo = excelData.columns[index];
      let name = columnInfo.name_in_db;
      let type = columnInfo.type;
      let scope = columnInfo.scope;
      let keyscope = columnInfo.keyscope;
      let edit = !columnInfo.edit;
      // let { name, value } = e.target;
      // if (type == "Date") {
      //   value = format(value, "yyyy-MM-dd");
      // } else if (type == "Integer") {
      //   if (value) {
      //     let newVal = toFloatMark(value);
      //     if (value.slice(-1) == ".") {
      //       value = newVal + ".";
      //     } else if (value.slice(-2) == ".0") {
      //       value = newVal + ".0";
      //     } else {
      //       value = newVal;
      //     }
      //   }
      // }

      let ControlType = Controls[type];
      value = value == "None" ? "" : value;
      let newField = (
        <>
          <ControlType
            name={name}
            key={name + rowIndex}
            size={"small"}
            value={value}
            onChange={(e) =>
              handleInputChange(e, type, rowIndex, edit, scope, keyscope)
            }
            disabled={edit}
            options={scope}
            keyscopes={keyscope}
            style={{
              borderRadius: "5px",
              lineHeight: "0.5 !important",
              // boxShadow: `2px 2px 4px rgba(0,0,0, 0.15)`,
              minHeight: "2rem",
            }}
            InputLabelProps={{
              style: {
                height: "1rem",
              },
              // disableUnderline: true,
            }}
            /* styles the input component */
            {...(type != "DropdownSingleSelect"
              ? {
                  inputProps: {
                    style: {
                      height: "1rem",
                      padding: "0.5rem 0.5rem",
                      textAlign: "center",
                    },
                  },
                }
              : {})}
            componentSize={"small"}
            className={"table-data"}
          />
        </>
      );

      setExcelRows((prevRows) => {
        let tempRows = [...prevRows];
        tempRows[rowIndex] = { ...tempRows[rowIndex], [name]: newField };
        return tempRows;
      });
    }
  };

  useEffect(() => {
    if (excelData.data?.length > 0) {
      const tempRows = [];
      let columnsData = excelData.columns;

      let initColumns = [];

      excelData.data.map((row, key) => {
        let newRow = {};
        for (const column of columnsData) {
          if (column.type == "Datetime") {
            column.type = "Date";
          }
          let ControlType = Controls[column.type];

          newRow[column.name_in_db] = row[column.name_in_db] || "None";
        }
        newRow["delete"] = (
          <MDBox
            onClick={(e) => {
              handleDeleteRow(reference, row["_id"]);
            }}
          >
            <Tooltip title={"Delete Record"}>
              <FontAwesomeIcon icon={faTrash} color="red"></FontAwesomeIcon>
            </Tooltip>
          </MDBox>
        );
        tempRows.push(newRow);
      });

      initColumns = columnsData.map((column) => {
        return {
          Header: convertKeyToName(column.name_in_db),
          accessor: column.name_in_db,
          align: "center",
          headerType: column.type,
        };
      });
      initColumns.push({
        Header: "Delete",
        accessor: "delete",
        align: "center",
      });
      setExcelColumns(initColumns);
      setExcelRows(tempRows);
      setUpdatedRows([]);
    } else if (excelData.data?.length == 0) {
      setExcelColumns([]);
      setExcelRows([]);
      setUpdatedRows([]);
    }
  }, [excelData]);

  useEffect(() => {
    if (reference) {
      fetchExcelAPI();
    }
  }, [reference, filters]);

  return [excelRows, excelColumns, pageInfo, handleRowClick, updatedRows];
};

export default useExcelData;
