import emptyImg from "assets/images/empty.png";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

function EmptyResult({ text ,...props}) {
  return (
    <MDBox
      sx={{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%,-50%)",
        height:"60%",

      }}
      {...props}
    >
      <MDBox sx={{display:"flex",flexDirection:"column",height:"100%",justifyContent:"center",alignItems:"center"}}>
        <img src={emptyImg} height={"100%"} width={"auto"} />
        <MDTypography sx={{whiteSpace:"nowrap"}}>{text || "No Results Found"}</MDTypography>
      </MDBox>
    </MDBox>
  );
}

export default EmptyResult;
