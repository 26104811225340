import React from "react";
import {
  FormControl,
  InputLabel,
  Select as MuiSelect,
  MenuItem,
  FormHelperText,
  Paper,
  makeStyles,
} from "@material-ui/core";
import { Autocomplete, Box, Chip, TextField, Tooltip, Typography } from "@mui/material";
import "css/controls/autocomplete.css";

const useStyles = makeStyles({
  popper: { minWidth: "max-content !important" },
});

export default function CustomAutoComplete({
  name,
  label,
  value,
  onChange,
  options,
  disabled,

  ...props
}) {
  const classes = useStyles();
  return (
    <FormControl variant="outlined" disabled={disabled} fullWidth>
      <Autocomplete
        value={value}
        onChange={(e, newValue) => {
          onChange({ target: { name: name, value: newValue } });
        }}
        
        size={"medium"}
        disabled={disabled}
        options={options}

        {...props}
        getOptionLabel={(option) => {
          return option?.name ? option.name : option;
        }}
        classes={{ popper: classes.popper }}
        renderInput={(params) => {
          return (
            <TextField
              {...params}
              label={
                label && (props.size=="small"?<Typography>{label}</Typography>:<Typography variant="headline" sx={{fontSize:"1rem !important",fontWeight:"500" }} className={"font-unset"}> {label} </Typography>)
              }
              placeholder={label}
              InputProps={{
                ...params.InputProps,

                endAdornment: <></>,
              }}
              // {...props}
              sx={{ width: "100% !important", margin: "0 !important" }}
            />
          );
        }}
        renderTags={() => {
          return (
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                gap: 0.1,
                justifyContent: "left",
              }}
            >
              <Tooltip title={value} placement="bottom-start">
                <Chip
                  key={value}
                  label={value}
                  sx={{
                    fontSize: "x-small",
                    height: "fit-content",
                  }}
                />
              </Tooltip>
            </Box>
          );
        }}
        sx={{ width: "100% !important" }}
      />
    </FormControl>
  );
}
