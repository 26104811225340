import DashboardLayout from "examples/LayoutContainers/DashboardLayout";

import { TabContext, TabPanel } from "@mui/lab";
import { AppBar, Icon, Tab, Tabs } from "@mui/material";
import { setGlobalState } from "Global";
// import { caseAPI } from "api";
// import AdvancedAnalytics from "layouts/dashboard/advancedAnalytics";
// import AgentAnalytics from "layouts/dashboard/agentAnalytics";
// import BasicAnalytics from "layouts/dashboard/basicAnalytics";
// import ProcessMining from "layouts/dashboard/processMining";
import { useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import OTDDashboard from "layouts/dashboard/otdDashboard";
import MainDashboard from "./mainDashboard";
import OTCDashboard from "./otcDashboard";
import VFRDashboard from "./vfrDashboard";
import OTC2Dashboard from "./otc2Dashboard";
import OTD2Dashboard from "./otd2Dashboard";

function Dashboard(props) {
  const navigation = useNavigate();
  const [tabValue, setTabValue] = useState("1");
  const handleSetTabValue = (event, newValue) => setTabValue(newValue);
  const [days, setDays] = useState(90);
  const [state, setState] = useState({});

  const [filter, setFilter] = useState({
    days: 90,
    resolved_only: false,
    level: 1,
    variants: [],
  });

  useEffect(() => {
    // caseAPI
    //   .getAgents()
    //   .then((res) => {
    //     if (res.status == 200) {
    //       const agents = res.data;
    //       setFilter({
    //         ...filter,
    //         agents: agents,
    //         agent_email: agents[0].email_id,
    //       });
    //     }
    //   })
    //   .catch((error) => {
    //     if ([401].includes(error.response?.status)) {
    //       navigation("/authentication/sign-in");
    //       setGlobalState("error", {
    //         open: true,
    //         message: "Login Expired! Please Login Again",
    //         type: "error",
    //       });
    //     }else if (![422].includes(error.response?.status)) {
    //       setGlobalState("error", {
    //         open: true,
    //         message: error.response.data,
    //         type: "error",
    //       });
    //     }
    //   });
  }, []);

  return (
    <DashboardLayout>
      {/* <DashboardNavbar user={props?.user} /> */}
      <TabContext value={tabValue}>
        <AppBar position="static" sx={{ margin: "0px 0px 20px 0px" }}>
          <Tabs
            orientation={"horizontal"}
            value={tabValue}
            onChange={handleSetTabValue}
          >
            <Tab
              label="Main"
              icon={
                <Icon fontSize="small" sx={{ mt: -0.25 }}>
                  analytics
                </Icon>
              }
              value={"1"}
            />
            <Tab
              label="OTC-BASE"
              icon={
                <Icon fontSize="small" sx={{ mt: -0.25 }}>
                  timeline
                </Icon>
              }
              value={"2"}
            />
                        <Tab
              label="OTC-DSADAKAR"
              icon={
                <Icon fontSize="small" sx={{ mt: -0.25 }}>
                  timeline
                </Icon>
              }
              value={"3"}
            />
            <Tab
              label="OTD-BASE"
              icon={
                <Icon fontSize="small" sx={{ mt: -0.25 }}>
                  timeline
                </Icon>
              }
              value={"4"}
            />
                        <Tab
              label="OTD-PHC"
              icon={
                <Icon fontSize="small" sx={{ mt: -0.25 }}>
                  timeline
                </Icon>
              }
              value={"5"}
            />
            <Tab
              label="VFR"
              icon={
                <Icon fontSize="small" sx={{ mt: -0.25 }}>
                  timeline
                </Icon>
              }
              value={"6"}
            />
          </Tabs>
        </AppBar>
        <TabPanel value="1" index={0}>
          <MainDashboard />
        </TabPanel>
        <TabPanel value="2" index={1}>
          <OTCDashboard />
        </TabPanel>
        <TabPanel value="3" index={1}>
          <OTC2Dashboard />
        </TabPanel>
        <TabPanel value="4" index={1}>
          <OTDDashboard />
        </TabPanel>
        <TabPanel value="5" index={1}>
          <OTD2Dashboard />
        </TabPanel>
        <TabPanel value="6" index={2}>
          <VFRDashboard />
        </TabPanel>
      </TabContext>
    </DashboardLayout>
  );
}
export default Dashboard;
