// @mui material components
import Icon from "@mui/material/Icon";

//  React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDProgress from "components/MDProgress";
import Checkbox from "@mui/material/Checkbox";

// Images
import { useEffect, useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { setGlobalState } from "Global";
import { ExcelDataAPI } from "api";

const useExcelData = ({ reference, setIsLoading, dataAPI, filters }) => {
  const navigation = useNavigate();
  const [excelData, setExcelData] = useState([]);

  const [excelRows, setExcelRows] = useState([]);

  const [excelColumns, setExcelColumns] = useState([]);
  const [pageInfo, setPageInfo] = useState({});

  const convertKeyToName = (key) => {

      let name = key
        .split("_")
        .map((word) => {
          if(word.length>0)
          {return word[0].toUpperCase() + word.slice(1);}else{
            return word
          }
        })
        .join(" ");

    return name;
  };

  const fetchExcelAPI = async () => {
    setIsLoading(true);
    try {
      const dynamicFunction = ExcelDataAPI[`get${dataAPI}Table`];
      const res = await dynamicFunction({ reference: reference, ...filters });
      setPageInfo({ count: res.data.count, pages: res.data.pages });
      setExcelData(res.data.data);
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);

    }
  };

  useEffect(() => {
    const tempRows = [];

    let initColumns =
      excelData && excelData.length > 0 ? Object.keys(excelData[0]) : [];

    excelData.map((row, key) => {
      let rows = {};
      for (const column of initColumns) {
        rows[column] = row[column];
      }
      tempRows.push(rows);
    });

    initColumns = initColumns.map((column) => {
      return {
        Header: convertKeyToName(column),
        accessor: column,
        align: "center",
      };
    });

    setExcelColumns(initColumns);
    setExcelRows(tempRows);
  }, [excelData]);

  useEffect(() => {
    fetchExcelAPI();
  }, [reference, filters]);

  return [excelRows, excelColumns, pageInfo];
};

export default useExcelData;
