import { useEffect, useState } from "react";

// react-router components
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";

// @mui material components
import CssBaseline from "@mui/material/CssBaseline";
import Icon from "@mui/material/Icon";
import { ThemeProvider } from "@mui/material/styles";

import Bulk from "layouts/bulkupload/index";

//  React components
import MDBox from "components/MDBox";

//  React example components
import Configurator from "examples/Configurator";
import Sidenav from "examples/Sidenav";

//  React themes
import theme from "assets/theme";

//  React Dark Mode themes
import themeDark from "assets/theme-dark";

import {
  setMiniSidenav,
  setOpenConfigurator,
  useMaterialUIController,
} from "context";

import { client, client2 } from "api";
import logo from "assets/images/ef-logo-72.png";
import { createBrowserHistory } from "history";
import AccessDenied from "layouts/authentication/access-denied";
import CustomLogin from "layouts/authentication/microsoftlogin";
import SignIn from "layouts/authentication/sign-in";
import SignUp from "layouts/authentication/sign-up";
import ResetPassword from "layouts/authentication/reset-password";
import Dashboard from "layouts/dashboard";
// import Forms from "layouts/forms";
// import History from "layouts/history/index";
import Logout from "layouts/logout";
// import Profile from "layouts/profile";
// import Tables from "layouts/tables";
// import CreateCase from "layouts/tables/createCase";
// import WorkflowUpdate from "layouts/workflowUpdate/workflowUpdate";
// import WorkflowView from "layouts/workflowView/workflowview";
import CustomAlert from "utils/alert";
import Tables from "layouts/tables";
import { defaultRoutes } from "routes";
import { setGlobalState } from "Global";
import { basicAPI } from "api";

export default function App() {
  const [controller, dispatch] = useMaterialUIController();
  const {
    miniSidenav,
    direction,
    layout,
    openConfigurator,
    sidenavColor,
    transparentSidenav,
    whiteSidenav,
    darkMode,
  } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const [rtlCache, setRtlCache] = useState(null);
  const { pathname } = useLocation();
  const navigation = useNavigate();
  const [user, setUser] = useState({});
  const history = createBrowserHistory();
  const defaultRoute = "/dashboard";


  const [routes, setRoutes] = useState(null);

  client.interceptors.response.use(
    function (response) {
      return response;
    },
    function (error) {
      globalErrorHandler(error);

      return Promise.reject(error);
    }
  );

  client2.interceptors.response.use(
    function (response) {
      return response;
    },
    function (error) {
      globalErrorHandler(error);

      return Promise.reject(error);
    }
  );

  const globalErrorHandler = (error) => {
    if (error.response) {
      if ([401].includes(error.response?.status)) {
        sessionStorage.clear();
        if (window.location.pathname != "/authentication/sign-in") {
          basicAPI.logout().then((res) => {
            if (res.status == 200) {
              navigation("/authentication/sign-in");
              setGlobalState("error", {
                open: true,
                message: "Login Expired! Please Login Again",
                type: "error",
                code: error.response.status,
              });
            }
          });
        }
      } else if ([422].includes(error.response?.status)) {
        setGlobalState("error", {
          open: true,
          message: "Invalid format. Input value or format is Invalid.",
          type: "error",
          code: error.response.status,
        });
      } else if (![422, 455, 456, 304].includes(error.response?.status)) {
        if (typeof error.response?.data == typeof "") {
          setGlobalState("error", {
            open: true,
            message: error.response?.data,
            type: "error",
            code: error.response.status,
          });
        }
      }
    }
  };

  const unlisten = history.listen(({ location, action }) => {
    console.log(action, location.pathname);
  });

  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  // Change the openConfigurator state
  const handleConfiguratorOpen = () =>
    setOpenConfigurator(dispatch, !openConfigurator);

  // Setting the dir attribute for the body element
  useEffect(() => {
    document.body.setAttribute("dir", direction);
  }, [direction]);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  useEffect(() => {
    if (
      ["/authentication/sign-up", "/authentication/reset-password"].includes(
        window.location.pathname
      )
    ) {
      // basicAPI.userData().then((res)=>{
      //   sessionStorage.setItem("user", JSON.stringify(res.data));
      // })
    } else if (!sessionStorage.getItem("token")) {
      let currLocation = window.location.pathname;
      if (window.location.pathname != "/logout") {
        basicAPI
          .userData()
          .then((res) => {
            if (res.status == 200) {
              const tempUser = res.data;
              sessionStorage.setItem(
                "token",
                `Bearer ${res.data.access_token}`
              );
              sessionStorage.setItem("user", JSON.stringify(res.data));
              setUser(tempUser);
              const temproutes = defaultRoutes.filter((route) => {
                if (route && route.access.includes(tempUser.permission)) {
                  return route;
                }
              });

              setRoutes(temproutes);

              client.defaults.headers.common.Authorization = `${sessionStorage.getItem(
                "token"
              )}`;
              client2.defaults.headers.common.Authorization = `${sessionStorage.getItem(
                "token"
              )}`;

              navigation(currLocation);
            }
          })
          .catch((err) => {
            sessionStorage.setItem("history-url", currLocation);
            navigation("/authentication/sign-in", {
              state: { navigateTo: history.location },
            });
          });
      }
    } else {
      client.defaults.headers.common.Authorization = `${sessionStorage.getItem(
        "token"
      )}`;
      client2.defaults.headers.common.Authorization = `${sessionStorage.getItem(
        "token"
      )}`;
      setUser(JSON.parse(sessionStorage.getItem("user")));
      const tempUser = JSON.parse(sessionStorage.getItem("user"));

      const temproutes = defaultRoutes.filter((route) => {
        if (route && route.access.includes(tempUser.permission)) {
          return route;
        }
      });

      setRoutes(temproutes);
      if (window.location.pathname == "/authentication/sign-in") {
        navigation("/");
      }
      // navigation("/dashboard")
    }

  }, [window.location.pathname]);

  useEffect(() => {
    if (sessionStorage.getItem("user")) {
      client.defaults.headers.common.Authorization = `${sessionStorage.getItem(
        "token"
      )}`;
      client2.defaults.headers.common.Authorization = `${sessionStorage.getItem(
        "token"
      )}`;
      setUser(JSON.parse(sessionStorage.getItem("user")));
      const tempUser = JSON.parse(sessionStorage.getItem("user"));

      const temproutes = defaultRoutes.filter((route) => {
        if (route && route.access.includes(tempUser.permission)) {
          return route;
        }
      });
      setRoutes(temproutes);
    }
  }, [sessionStorage.getItem("user")]);

  const configsButton = (
    <MDBox
      display="flex"
      justifyContent="center"
      alignItems="center"
      width="3.25rem"
      height="3.25rem"
      bgColor="white"
      shadow="sm"
      borderRadius="50%"
      position="fixed"
      right="2rem"
      bottom="2rem"
      zIndex={99}
      color="dark"
      sx={{ cursor: "pointer" }}
      onClick={handleConfiguratorOpen}
    >
      <Icon fontSize="small" color="inherit">
        settings
      </Icon>
    </MDBox>
  );

  return (
    <>
      <ThemeProvider theme={darkMode ? themeDark : theme}>
        <CssBaseline />
        {layout === "dashboard" && (
          <>
            {/* {console.log(routes)} */}
            {routes?.length > 0 && (
              <Sidenav
                color={sidenavColor}
                brand={logo}
                brandName="Neat Process"
                routes={routes}
                user={user}
                onMouseEnter={handleOnMouseEnter}
                onMouseLeave={handleOnMouseLeave}
              />
            )}
            <Configurator />
          </>
        )}
        {layout === "vr" && <Configurator />}
        <CustomAlert />
        <Routes>
          <Route path="/authentication/sign-in" element={<SignIn />} />
          <Route path="/authentication/sign-up" element={<SignUp />} />
          <Route
            path="/authentication/reset-password"
            element={<ResetPassword />}
          />
          <Route path="/access-denied" element={<AccessDenied />} />
          <Route path="/login-callback" element={<CustomLogin />} />

          {routes?.length > 0 &&
            routes.map((route) => {
              if (
                route &&
                route.path &&
                route.access?.includes(user.permission)
              ) {
                return (
                  <>
                    {
                      <Route
                        path={route.path}
                        element={route.component}
                        key={route.key}
                      />
                    }
                  </>
                );
              }
            })}

          {routes?.length > 0 && (
            <Route path="/" element={<Navigate to={defaultRoute} />} />
          )}
          {user?.access_token && (
            <Route path="*" element={<Navigate to={defaultRoute} />} />
          )}
        </Routes>
      </ThemeProvider>
    </>
  );
}
