import MDBox from "components/MDBox";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

function ChartSkeleton(props) {
  return (
    <SkeletonTheme highlightColor="white" {...props}>
      <MDBox
        sx={{
          textAlign: "center",
          fontSize: "medium !important",
          padding: "0.5rem",
          fontWeight: "500",
          paddingTop: "1rem",
        }}
        className={"font-unset"}
      >
       <Skeleton height={"1rem"}  width={"10rem"}/>
      </MDBox>
      <MDBox sx={{ width: "100%", height: "100%" }} pt={1} px={2} mb={1}>
        <Skeleton height={"13rem"} />
      </MDBox>
    </SkeletonTheme>
  );
}

export default ChartSkeleton;
