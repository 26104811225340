/*
 * This file serves as a base file for any API interactions with other applications.
 * All API calls, declarations, definitions are included here.
 */
import axios from "axios";


const url = "";


export let client = axios.create({
  baseURL: process.env.REACT_APP_API_URL_MAIN ,
  withCredentials:true
});


export let client2 = axios.create({
  baseURL: process.env.REACT_APP_API_URL_PM,
  withCredentials:true
});


export const basicAPI = {
  signin(username, password) {
    const params = { username: username, password: password };
    return client.post(`${url}/signin`, null, { params: params });
  },

  signinNew(username, password) {
    const data = { username: username, password: password };
    return client.post(`${url}/signin_new`, data);
  },

  signinKey() {
    return client.get(`${url}/signin_new/public_key`);
  },

  login() {
    return client.get(`${url}/login`);
  },

  logout() {
    return client.get(`${url}/logout`);
  },

  userData(){
    return client.get(`${url}/me`);
  },

  loginCallback(code) {
    const params = { code: code };
    return client.get(`${url}/login/callback`, { params: params });
  },
};

export const agentAPI = {
  // New api
  getAllCases(paramsData) {
    const params = paramsData;
    return client.get(`${url}/get_all_cases`, { params: params });
  },

  getAllCasesAdmin(paramsData) {
    const params = paramsData;
    return client.get(`${url}/get_all_cases_admin`, { params: params });
  },

  getAgentDetails(agentId) {
    const params = { agent_id: agentId };
    return client.get(`${url}/get_single_agent_details`, { params: params });
  },
};

export const featureAPI = {
  getFeatures(case_id, step_name) {
    const params = {
      case_id: case_id,
      step_name: step_name,
    };
    return client.get(`${url}/get_features`, { params: params });
  },

  getFiles(case_id, step_name) {
    const params = {
      case_id: case_id,
      step_name: step_name,
    };
    return client.get(`${url}/get_files`, { params: params });
  },

  updateFeatures(case_id, step_name, data) {
    const params = {
      case_id: case_id,
      step_name: step_name,
    };
    return client({
      url: `${url}/update_features`,
      method: "POST",
      data,
      params: params,
      // headers: { "content-type": "multipart/form-data" },
    });
  },

  updateFiles(case_id, step_name, data) {
    const params = {
      case_id: case_id,
      step_name: step_name,
    };
    return client({
      url: `${url}/update_files`,
      method: "POST",
      data: data,
      params: params,
      headers: { "content-type": "multipart/form-data" },
    });
  },
};

export const userAPI = {
  getUserDetails() {
    return client.get(`${url}/get_current_user_details`);
  },

  updateUserDetails(userDetails) {
    return client.post(`${url}/update_user_details`, null, {
      params: userDetails,
    });
  },
};

export const workflowAPI = {
  getCustomerCodeMaps() {
    return client.post(`${url}/get_all_customer_code_maps`, null, {});
  },
  updateCustomerCodeMaps(data) {
    const params = data;
    return client.post(`${url}/update_customer_code_map_features`, data, {
      params: params,
    });
  },
};

export const caseAPI = {
  createCase(caseDetails) {
    const params = {
      assigned_agent_email: caseDetails.assigned_agent_email,
      workflow_id: caseDetails.workflow_id,
    };
    return client.post(`${url}/create_case`, null, { params: params });
  },

  getWorkflowById(workflowId) {
    const params = {
      workflow_id: workflowId,
    };
    return client.get(`${url}/get_workflow_by_id`, { params: params });
  },

  getNextPaths(caseId) {
    const params = { case_id: caseId };
    return client.get(`${url}/get_available_paths`, { params: params });
  },

  getAllPaths(caseId) {
    const params = { case_id: caseId };
    return client.get(`${url}/get_all_paths`, { params: params });
  },

  changeStatus(caseId, nextStep, checked, email_id) {
    let params = {
      case_id: caseId,
      next_step: nextStep,
      auto_assign: false,
    };
    if (checked) {
      params = {
        ...params,
        auto_assign: checked,
        auto_assignee_email: email_id,
      };
    }
    return client.get(`${url}/change_case_status`, { params: params });
  },

  changeState(caseId, caseState) {
    const params = { case_id: caseId, case_state: caseState };
    return client.get(`${url}/change_case_state`, { params: params });
  },

  changeWorkflow({ caseId, nextStep, workflowId }) {
    const params = {
      case_id: caseId,
      next_step: nextStep,
      workflow_id: workflowId,
    };
    return client.get(`${url}/change_case_workflow_and_status`, {
      params: params,
    });
  },

  updateWorflowAutoTransfer(workflowId, StepName, agent_email = null) {
    const params = {
      agent_email: agent_email,
      step_name: StepName,
      workflow_id: workflowId,
    };
    return client.post(`${url}/update_workflow_auto_transfer_email`, null, {
      params: params,
    });
  },

  updateWorflowInitAgent(workflowId, agent_email) {
    const params = {
      agent_email: agent_email,
      workflow_id: workflowId,
    };
    return client.post(`${url}/update_workflow_init_agent`, null, {
      params: params,
    });
  },

  changeAgent(caseId, emailTo, message) {
    const params = { case_id: caseId, message: message };
    const data = { value: emailTo };
    return client({
      url: `${url}/change_case_agent`,
      method: "POST",
      data,
      params: params,
    });
  },

  getCase(caseId) {
    const params = { case_id: caseId };
    return client.get(`${url}/get_case_details`, { params: params });
  },

  getHistory(params) {
    return client.get(`${url}/get_history_for_case_id`, { params: params });
  },

  getAgents() {
    return client.get(`${url}/get_all_agents`);
  },

  getOwners() {
    return client.get(`${url}/get_all_owners`);
  },

  getWorkflows() {
    return client.get(`${url}/get_all_workflows`);
  },

  getAutoAssignedPerson(case_id, step_name) {
    const params = { case_id: case_id, next_step_chosen: step_name };
    return client.get(`${url}/get_auto_assigned_person`, { params: params });
  },

  updateCase(caseDetails) {
    const params = {
      case_id: caseDetails.case_id,
      agent_id: caseDetails.agent_id,
      current_status: caseDetails.current_status,
      claim: caseDetails.claim,
    };
    return client.post(`${url}/create_case`, null, { params: params });
  },

  sendMail({ case_id, emailTo, emailCC, subject, body }) {
    const params = {
      case_id: case_id,
      header: subject,
      body: body,
      cc_email: emailCC,
    };
    const data = { value: emailTo };
    return client({
      url: `${url}/send_notification`,
      method: "POST",
      data,
      params: params,
    });
  },
};

export const chatAPI = {
  getComments(caseId) {
    const params = { case_id: caseId };
    return client.post(`${url}/read_comment_for_case`, null, {
      params: params,
    });
  },

  sendComments(caseId, message) {
    const params = { case_id: caseId, message: message };
    return client.post(`${url}/add_comment_for_case`, null, { params: params });
  },
};

export const caseDocumentAPI = {
  //bult upload

  uploadBulkCases(data, type) {
    if (type == "base") {
      return client({
        url: `${url}/excel_upload_base`,
        method: "POST",
        data,
        headers: { "content-type": "multipart/form-data" },
      });
    } else
      return client({
        url: `${url}/excel_upload`,
        method: "POST",
        data,
        headers: { "content-type": "multipart/form-data" },
      });
  },
};

export const bulkStatAPI = {
  getNewInsert() {
    return client.post(`${url}/get_newCasesBulkInsert`);
  },
  getResolve() {
    return client.post(`${url}/get_resolveCasesBulkInsert`);
  },
  getResolveConflict() {
    return client.post(`${url}/get_resolvedPresentBulkInsert`);
  },
  getUpdate() {
    return client.post(`${url}/get_updateCasesBulkInsert`);
  },
  getExcelData() {
    return client.post(`${url}/get_excelDataBulk`);
  },
  getRejectedData() {
    return client.post(`${url}/get_rejectedDataBulk`);
  },

  getInsertingStatus() {
    return client.get(`${url}/get_inserting_status`);
  },
  getUpdatingStatus() {
    return client.get(`${url}/get_update_status`);
  },
  getResolveStatus() {
    return client.get(`${url}/get_resolve_status`);
  },

  postInsert(data) {
    return client({
      url: `${url}/init_background_inserting`,
      method: "POST",
      data,
    });
  },
  postResolve(data) {
    return client({
      url: `${url}/init_background_resolving`,
      method: "POST",
      data,
    });
  },
  postUpdate(data) {
    return client({
      url: `${url}/init_background_updating`,
      method: "POST",
      data,
    });
  },
};

//signup-auth

export const SignUpAPI = {
  signUpEmail(email) {
    const data = { value: email };
    return client({
      url: `${url}/sign-up/emailverify`,
      method: "POST",
      data,
    });
  },
  signUpEmailResend(email) {
    const data = { value: email };
    return client({
      url: `${url}/sign-up/emailverify/resend`,
      method: "POST",
      data,
    });
  },
  signUpEmailOTP(params, email) {
    const data = { value: email };
    return client({
      url: `${url}/sign-up/emailverify/otp`,
      method: "POST",
      data,
      params: params,
    });
  },
  signUpEmailPasswd(params, email) {
    const data = { value: email };
    console.log(data, params);
    return client({
      url: `${url}/sign-up/emailverify/otp/setpasswd`,
      method: "POST",
      data,
      params: params,
    });
  },
};



// New application apis


export const DashboardAPI = {
  getFilterValues(params) {
    return client.post(`${url}/filter/common`,null,{params:params});
  },
  getFilterNames() {
    return client.post(`${url}/filter/get_all_filter_names`);
  },


  // Base Analytics
  getOTDBASEDataTile(params,data) {
    return client.post(`${url}/analytics/main/otd-base/tile`,data,{params:params});
  },  

  getOTDPHCDataTile(params,data) {
    return client.post(`${url}/analytics/main/otd-phc/tile`,data,{params:params});
  },
  getOTCBASEDataTile(params,data) {
    return client.post(`${url}/analytics/main/otc-base/tile`,data,{params:params});
  },  

  getOTCDSADAKARDataTile(params,data) {
    return client.post(`${url}/analytics/main/otc-dsadakar/tile`,data,{params:params});
  }, 
  getVFRDataTile(params,data) {
    return client.post(`${url}/analytics/main/vfr/tile`,data,{params:params});
  },  


  getOTDBASEDataTrend(params,data) {
    return client.post(`${url}/analytics/main/otd-base/trend`,data,{params:params});
  },  

  getOTDPHCDataTrend(params,data) {
    return client.post(`${url}/analytics/main/otd-phc/trend`,data,{params:params});
  },
  getOTCBASEDataTrend(params,data) {
    return client.post(`${url}/analytics/main/otc-base/trend`,data,{params:params});
  },  

  getOTCDSADAKARDataTrend(params,data) {
    return client.post(`${url}/analytics/main/otc-dsadakar/trend`,data,{params:params});
  }, 
  getVFRDataTrend(params,data) {
    return client.post(`${url}/analytics/main/vfr/trend`,data,{params:params});
  }, 




  getTrendGroupBy(params,data) {
    return client.post(`${url}/analytics/main/trend/groupby_names`,data,{params:params});
  }, 


  // Dedicated breakdown
  getOTDBASEDataSub(params,data) {
    return client.post(`${url}/analytics/sub/otd-base`,data,{params:params});
  },  
  getOTDPHCDataSub(params,data) {
    return client.post(`${url}/analytics/sub/otd-phc`,data,{params:params});
  },  
  getOTCBASEDataSub(params,data) {
    return client.post(`${url}/analytics/sub/otc-base`,data,{params:params});
  }, 
  getOTCDSADAKARDataSub(params,data) {
    return client.post(`${url}/analytics/sub/otc-dsadakar`,data,{params:params});
  },   
  getVFRDataSub(params,data) {
    return client.post(`${url}/analytics/sub/vfr`,data,{params:params});
  },  

  getGroupByOTD(params,data) {
    return client.post(`${url}/analytics/sub/otdotc/groupby_names`,data,{params:params});
  }, 

  getGroupByOTC(params,data) {
    return client.post(`${url}/analytics/sub/otdotc/groupby_names`,data,{params:params});
  }, 

  getGroupByVFR(params,data) {
    return client.post(`${url}/analytics/sub/vfr/groupby_names`,data,{params:params});
  }, 



  // OTD New Analytics

  getOTDGroupBy(params,data) {
    return client.post(`${url}/analytics/otd/groupby_names`,data,{params:params});
  },  

  getOTDTrend(params,data) {
    return client.post(`${url}/analytics/otd/trend`,data,{params:params});
  },  


  getOTDTrendGroupBy(params,data) {
    return client.post(`${url}/analytics/otd/trend/groupby_names`,data,{params:params});
  }, 

  

  // OTD (Domestic) Analytics
  getOTDDomesticCustomer(params,data) {
    return client.post(`${url}/analytics/otd/domestic/customer_wise`,data,{params:params});
  },  

  getOTDDomesticDeliveryRegion(params,data) {
    return client.post(`${url}/analytics/otd/domestic/region_wise`,data,{params:params});
  },  

  getOTDDomesticTrend(params,data)         {
    return client.post(`${url}/analytics/otd/domestic/trend`,data,{params:params});
  },  
  getOTDDomesticOverall(params,data)         {
    return client.post(`${url}/analytics/otd/domestic/overall`,data,{params:params});
  },  

  // OTD (Export) Analytics
  getOTDExportCustomer(params,data) {
    return client.post(`${url}/analytics/otd/export/customer_wise`,data,{params:params});
  },

  getOTDExportOffloadingPoint(params,data) {
    return client.post(`${url}/analytics/otd/export/offloading_point_wise`,data,{params:params});
  },

  getOTDExportTrend(params,data)         {
    return client.post(`${url}/analytics/otd/export/trend`,data,{params:params});
  },  

  getOTDExportOverall(params,data)         {
    return client.post(`${url}/analytics/otd/export/overall`,data,{params:params});
  },  

  // VFR Analytics
  getVFRCustomer(params,data) {
    return client.post(`${url}/analytics/vfr/customer_wise`,data,{params:params});
  },
  getVFRRegion(params,data) {
    return client.post(`${url}/analytics/vfr/region_wise`,data,{params:params});
  },
  getVFROverall(params,data) {
    return client.post(`${url}/analytics/vfr/overall`,data,{params:params});
  }
  ,
  getVFRTrend(params,data) {
    return client.post(`${url}/analytics/vfr/trend`,data,{params:params});
  }
};

export const ExcelDataAPI = {
  excelUpload(params,data) {

      return client({
        url: `${url}/excel_upload`,
        method: "POST",
        params:params,
        data,
        headers: { "content-type": "multipart/form-data" },
      });
    
  },
  getReferences() {
    return client.post(`${url}/get_supported_references`);
  },
  getMainTable(paramsData,data) {
    const params = paramsData;
    return client.post(`${url}/get_main_table`,data, { params: params });
  },

  updateMainRecords(paramsData,data) {
    const params = paramsData;
    return client.post(`${url}/update_records`,data, { params: params });
  },

  deleteMainRecord(paramsData,data) {
    const params = paramsData;
    return client.post(`${url}/delete_record`,data, { params: params });
  },
  getSuccessTable(paramsData,data) {
    const params = paramsData;
    return client.post(`${url}/get_success_table`,data, { params: params });
  }, 
  getFailTable(paramsData,data) {
    const params = paramsData;
    return client.post(`${url}/get_fail_table`,data, { params: params });
  },
};