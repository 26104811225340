// react-router components

// @mui material components
import Icon from "@mui/material/Icon";

import Bulk from "layouts/bulkupload/index";

//  React components

//  React example components

//  React themes

//  React Dark Mode themes

import AccessDenied from "layouts/authentication/access-denied";
import Dashboard from "layouts/dashboard";
// import Forms from "layouts/forms";
// import History from "layouts/history/index";
import Logout from "layouts/logout";
// import Profile from "layouts/profile";
// import Tables from "layouts/tables";
// import CreateCase from "layouts/tables/createCase";
// import WorkflowUpdate from "layouts/workflowUpdate/workflowUpdate";
// import WorkflowView from "layouts/workflowView/workflowview";
import Tables from "layouts/tables";

export const defaultRoutes = [
  // {
  //   type: "collapse",
  //   name: "Profile",
  //   key: "profile",
  //   icon: <Icon fontSize="small">person</Icon>,
  //   route: "/profile",
  //   path: "/profile",
  //   access: ["agent1", "admin", "agent2", "superuser"],
  //   component: <Profile />,
  // },
  {
    type: "collapse",
    name: "Dashboard",
    key: "dashboard",
    icon: (
      <Icon fontSize="medium" className="navbar-icon">
        analytics
      </Icon>
    ),
    route: "/dashboard",
    path: "/dashboard",
    access: ["agent", "admin", "agent2", "superuser"],
    component: <Dashboard />,
  },
  // {
  //   type: "collapse",
  //   name: "Cases",
  //   key: "cases",
  //   icon: <Icon fontSize="small">table_view</Icon>,
  //   path: "/:agent_id/cases",
  //   access: ["agent1", "admin", "agent2", "superuser"],
  //   component: <Tables user={user} type={"user"} />,
  // },
  {
    type: "collapse",
    name: "Data Table",
    key: "deliveriesData",
    icon: (
      <Icon fontSize="medium" className="navbar-icon">
        table_view
      </Icon>
    ),
    path: "/deliveriesData",
    route: "/deliveriesData",
    access: ["agent", "admin", "superuser"],
    component: <Tables />,
  },

  // {
  //   type: "collapse",
  //   name: "History",
  //   key: "history",
  //   icon: <Icon fontSize="small">history</Icon>,
  //   route: "/history",
  //   path: "/history",
  //   access: ["agent1", "admin", "agent2", "superuser"],
  //   component: <History />,
  // },

  // {
  //   type: "",
  //   name: "History",
  //   key: "history",
  //   icon: <Icon fontSize="small">history</Icon>,
  //   route: "/history",
  //   path: "/history/:case_id",
  //   access: ["agent1", "admin", "agent2", "superuser"],
  //   component: <History />,
  // },
  {
    type: "collapse",
    name: "File Upload",
    key: "fileUpload",
    icon: (
      <Icon fontSize="medium" className="navbar-icon">
        upload
      </Icon>
    ),
    route: "/fileUpload",
    path: "/fileUpload",
    access: ["agent", "admin", "superuser"],
    component: <Bulk />,
  },
  // {
  //   type: "",
  //   name: "Case",
  //   key: "case",
  //   icon: <Icon fontSize="small">notifications</Icon>,
  //   route: "/cases/case",
  //   path: "/cases/:case_id",
  //   access: ["agent1", "admin", "superuser", "agent2"],
  //   component: <Forms />,
  // },
  // {
  //   type: "collapse",
  //   name: "Create Case",
  //   key: "createCase",
  //   icon: <Icon fontSize="small">add</Icon>,
  //   route: "/createCase",
  //   path: "/createCase",
  //   access: ["admin", "superuser"],
  //   component: <CreateCase />,
  // },
  // {
  //   type: "collapse",
  //   name: "Worflow Update",
  //   key: "workflowUpdate",
  //   icon: <Icon fontSize="small">update</Icon>,
  //   route: "/workflowUpdate",
  //   path: "/workflowUpdate",
  //   access: ["superuser"],
  //   component: <WorkflowUpdate />,
  // },
  // {
  //   type: "collapse",
  //   name: "Worflow View",
  //   key: "workflowview",
  //   icon: <Icon fontSize="small">account_tree </Icon>,
  //   route: "/workflowview",
  //   path: "/workflowview",
  //   access: ["agent2", "superuser", "agent1", "admin"],
  //   component: <WorkflowView />,
  // },
  {
    type: "collapse",
    name: "Logout",
    key: "access-denied",
    icon: (
      <Icon fontSize="medium" className="navbar-icon">
        logout
      </Icon>
    ),
    route: "/logout",
    path: "/logout",
    access: ["agent2", "superuser", "agent", "admin"],

    component: <Logout />,
  },
  {
    type: "",
    name: "Access Denied",
    key: "access-denied",
    icon: (
      <Icon fontSize="medium" className="navbar-icon">
        assignment
      </Icon>
    ),
    route: "/access-denied",
    path: "/access-denied",
    access: ["agent2", "superuser", "agent", "admin"],

    component: <AccessDenied />,
  },
];
