import { InputAdornment, TextField, Typography } from "@mui/material";
import "css/controls/custominput.css"
// import { Input } from '@mui/base/Input';

export default function CustomInput({
  name,
  label,
  value,
  error = null,
  onChange,
  disabled = false,
  unit = false,
  ...props
}) {
  return (
    <TextField
      variant="outlined"
      label={
        label && (
          <Typography
            variant="headline"
            sx={{ fontSize: "1rem !important", fontWeight: "500" }}
            className={"font-unset"}
          >
            {" "}
            {label}{" "}
          </Typography>
        )
      }
      // label={label}
      name={name}
      value={value}
      InputProps={
        unit
          ? {
              startAdornment: <InputAdornment>ZAR</InputAdornment>,
              disableUnderline: true,
            }
          : {}
      }

      onChange={onChange}
      disabled={disabled}
      {...(error && { error: true, helperText: error })}
      {...props}
      className={"custom-input "+props.className}
    />
  );
}
