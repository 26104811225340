import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  RadioGroup,
  FormControlLabel,
  Radio,
  Dialog,
  Grid,
  TextField,
  Icon,
  Card,
} from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import { useEffect, useState, useLayoutEffect } from "react";
import MDProgress from "components/MDProgress";
// import "react-medium-image-zoom/dist/styles.css";
import { useGlobalState, setGlobalState } from "Global";
import { useNavigate } from "react-router-dom";
import BasicAnalyticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard/basicAnalytics";
import Plot from "react-plotly.js";
import LoadingOverlay from "react-loading-overlay";
import { AppBar, Tabs, Tab } from "@mui/material";
import { DashboardAPI } from "api";
import { format } from "date-fns";
import Controls from "components/controls/Controls";
import TableDisplay from "layouts/tableDisplay/tableDisplay";
import AnalyticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard/analytics";
import CustomAutoComplete from "components/controls/AutoComplete";
import { BaseChart } from "examples/Charts/baseChart";
import { convertKeyToName } from "utils/conversion";
import EmptyResult from "utils/emptyResult";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

const date = new Date();

export const basicFilterMapping = {
  StartDate: format(
    new Date(date.getFullYear(), date.getMonth(), 1),
    "yyyy-MM-dd"
  ),
  EndDate: format(date, "yyyy-MM-dd"),
};

const dateFilters = ["StartDate", "EndDate"];

const tableFilterMap = {
  1: { "Local Or Export": [{ name: "Local", value: "Local" }] },
  2: { "Local Or Export": [{ name: "Export", value: "Export" }] },
};

function OTDDashboard() {
  const [basicFilterOptions, setBasicFilterOptions] = useState({});
  const [basicFilterMap, setBasicFilterMap] = useState({
    1: {
      ...basicFilterMapping,
      "Local Or Export": [{ name: "Local", value: "Local" }],
    },
    2: {
      ...basicFilterMapping,
      "Local Or Export": [{ name: "Export", value: "Export" }],
    },
  });

  const [tableDataMap, setTableDataMap] = useState({ 1: {}, 2: {} });
  const [groupByMap, setGroupByMap] = useState({
    1: "parent_company_name",
    2: "weekly",
  });
  const [groupByOptions, setGroupByOptions] = useState([]);
  const [isLoadingMap, setIsLoadingMap] = useState({
    1: true,
    2: true,
    3: true,
  });
  const [filterNameMapping] = useGlobalState("mapping");

  const navigation = useNavigate();

  const getOTDData = async (
    index,
    type = "customer_type",
    newFilter = null
  ) => {
    const dynamicFunction = DashboardAPI[`getOTDBASEDataSub`];
    setIsLoadingMap((prevLoadingMap) => {
      return { ...prevLoadingMap, [index]: true };
    });
    let test = await dynamicFunction(
      {
        ...convertToParams(newFilter ? newFilter : basicFilterMap[index]),
        type: type,
      },
      convertToParams(newFilter ? newFilter : basicFilterMap[index])
    )
      .then(async (res) => {
        if (res.status == 200) {
          setIsLoadingMap((prevLoadingMap) => {
            return { ...prevLoadingMap, [index]: false };
          });
          setTableDataMap((prevTableDataMap) => {
            return {
              ...prevTableDataMap,
              [index]: {
                data: res.data.data,
                count: res.data.count,
                pages: res.data.pages,
                totals: res.data.totals,
              },
            };
          });
        }
      })
      .catch((error) => {
        setIsLoadingMap((prevLoadingMap) => {
          return { ...prevLoadingMap, [index]: false };
        });
      });
  };

  const getBasicFilterValues = () => {
    let filterNames = {};
    DashboardAPI.getFilterNames()
      .then((res) => {
        filterNames = res.data["dashboard_common"];
        let filterNamesMapping = {};
        let filterMapping = {};
        filterNames.map((filter) => {
          if (filter.type != "Date" || filter.type != "Datetime") {
            filterNamesMapping[filter.name] = [];
          }
          filterMapping[filter.name] = filter.value;
        });
        setBasicFilterMap((prev) => {
          return {
            ...prev,
            1: { ...filterNamesMapping, ...prev[1] },
            2: { ...filterNamesMapping, ...prev[2] },
          };
        });
        setGlobalState("mapping", (prevState) => {
          return { ...prevState, ...filterMapping };
        });
        return filterNames;
      })
      .then((filterNames) => {
        filterNames.map((filterName) => {
          DashboardAPI.getFilterValues({ filter_name: filterName.value }).then(
            (res) => {
              let tempData = res.data;
              tempData.push({ name: "All", value: "All" });
              setBasicFilterOptions((prevFilterOptions) => {
                return { ...prevFilterOptions, [filterName.name]: tempData };
              });
            }
          );
        });
      });
  };

  const getGroupByOptions = () => {
    const dynamicFunction = DashboardAPI[`getGroupByOTD`];
    dynamicFunction().then((res) => {
      if (res.status == 200) {
        setGroupByOptions(res.data);
      }
    });
  };

  const handleBasicFilterChange = (event, index, type = null) => {
    let { name, value } = event.target;
    if (
      Object.prototype.toString.call(value) === "[object Date]" ||
      type == "Date"
    ) {
      value = format(value, "yyyy-MM-dd");
    } else if (typeof value == typeof [] && value.length > 0) {
      let index = value.length - 1;
      let tempVal = value[index];
      if (tempVal.value == "All") {
        value = [];
      }
    }

    let tempBasicFilter = {
      ...basicFilterMap[index],
      [name]: value,
    };
    setBasicFilterMap((prevBasicFilterMap) => {
      return { ...prevBasicFilterMap, [index]: tempBasicFilter };
    });
    getOTDData(index, groupByMap[index], tempBasicFilter);
  };

  const handleGroupBy = (event, index) => {
    let { name, value } = event.target.value;
    setGroupByMap((prevGroupByMap) => {
      return { ...prevGroupByMap, [index]: value };
    });
    getOTDData(index, value);
  };

  const resetData = async () => {
    setTableDataMap({ 1: {}, 2: {} });
    return true;
  };

  const getAllData = async () => {
    let temp = await resetData();
    Object.keys(tableFilterMap).map((index) => {
      getOTDData(index, groupByMap[index], basicFilterMap[index]);
    });
  };

  const convertToParams = (params) => {
    let tempParams = {};
    if (params) {
      Object.keys(params).forEach((param) => {
        if (params[param]) {
          let tempValue = params[param];
          if (typeof tempValue == typeof []) {
            tempValue = tempValue.map((tempValue) => {
              return tempValue.value;
            });
          }
          tempParams[filterNameMapping[param]] = tempValue;
        }
      });
    }
    return tempParams;
  };

  useEffect(() => {
    getBasicFilterValues();
    getGroupByOptions();
    getAllData();
  }, []);

  return (
    <MDBox py={3}>
      <Grid container spacing={6}>
        {/* Table Data */}
        <Grid
          item
          xs={12}
          sx={{ alignItems: "center", justifyContent: "center" }}
        >
          {/* Summary of Claims card */}
          {tableDataMap &&
            Object.keys(tableDataMap).map((index) => {
              return (
                <MDBox sx={{ marginTop: "1rem", marginBottom: "4rem" }}>
                  <MDBox>
                    <Grid
                      item
                      spacing={3}
                      display="flex"
                      flexDirection={"column !important"}
                      width={"100%"}
                      sx={{ minHeight: "10rem", position: "relative" }}
                    >
                      <MDBox
                        mx={2}
                        mt={-3}
                        py={2}
                        px={2}
                        mb={-3}
                        zIndex={10}
                        variant="gradient"
                        bgColor="info"
                        borderRadius="lg"
                        coloredShadow="info"
                      >
                        <MDTypography
                          variant="h6"
                          color="white"
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          {" "}
                          {groupByMap[index] == undefined && <Skeleton />}
                          {groupByMap[index] && (
                            <div>{`${convertKeyToName(
                              groupByMap[index]
                            )} Wise Breakdown`}</div>
                          )}
                        </MDTypography>
                      </MDBox>

                      <MDBox
                        sx={{
                          alignItems: "center",
                          height: "auto",
                          maxWidth: "100%",
                        }}
                      >
                        <TableDisplay
                          data={tableDataMap[index].data}
                          totals={tableDataMap[index].totals}
                          filters={basicFilterMap[index]}
                          filterOptions={basicFilterOptions}
                          handleFilterChange={(e) => {
                            handleBasicFilterChange(e, index);
                          }}
                          groupBy={groupByMap[index]}
                          groupByOptions={groupByOptions}
                          handleGroupChange={(e) => {
                            handleGroupBy(e, index);
                          }}
                          isLoading={isLoadingMap[index]}
                        />
                      </MDBox>
                    </Grid>
                  </MDBox>
                </MDBox>
              );
            })}
        </Grid>
      </Grid>
    </MDBox>
  );
}

export default OTDDashboard;
